import React, { useEffect, useState } from 'react';
import { ReactComponent as DeliveryIcon } from '../../../../Assets/riders.svg';
import { ReactComponent as DinInIcon } from '../../../../Assets/dine-in.svg';
import { ReactComponent as TakeAwayIcon } from '../../../../Assets/orders.svg';
import { ReactComponent as UPIIcon } from '../../../../Assets/upi.svg';
import { ReactComponent as CashIcon } from '../../../../Assets/cash.svg';
import { ReactComponent as CardIcon } from '../../../../Assets/card.svg';
import { OrderStatus } from '../Components/TableComponent';
import ListviewCustomerDetails from '../Components/ListviewCustomerDetails';
import ContentControllerSection from '../Components/ContentControllerSection';
import PaginationWithNumber from '../../../../Components/Pagination/PaginationWithNumber';
import { fetchOrders } from '../../Helper/functions';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { TableBlankComponentView } from '../../../../Components/BlankComponent/TableBlankComponentView';
import TableLoader from '../../../../Components/ContentLoader/TableLoader';

export default function FailedOrder(props) {
    const {
        setShowPopup,
        isMobileScreen,
        isKotView,
        isFetchingOrders,
        setIsFetchingOrder,
        orderType,
        selectedOrderingMode,
        selectedFilter,
        paginationState,
        setPaginationState,
        setOrderDetail,
    } = props;
    const today = new Date();
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const [orders, setOrders] = useState([]);
    const [selectedDates, setSelectedDates] = useState([today, today]);

    useEffect(() => {
        const fetchData = async () => {
            setIsFetchingOrder(true);
            const response = await fetchOrders({
                orderType,
                startDate: format(selectedDates[0], 'yyyy-MM-dd'),
                endDate: format(selectedDates[1], 'yyyy-MM-dd'),
                selectedOrderingMode,
                selectedOutletId,
                selectedRestaurantId,
                selectedFilter,
                perPage: paginationState.perPage,
                page: paginationState.currentPage,
            });
            if (response) {
            setPaginationState({
                currentPage: response?.orders?.current_page,
                lastPage: response?.orders?.last_page,
                perPage: response?.orders?.per_page,
            });
                setOrders(response?.orders?.data)
            } else {
                console.error('Unexpected response structure:', response);
                setOrders([]);
            }
            setIsFetchingOrder(false);
        };

        fetchData();
    }, [paginationState.currentPage,selectedFilter ,  paginationState.perPage, selectedDates, selectedRestaurantId, selectedOutletId]);

    const orderTypeIcon = (orderingMode) => {
        return orderingMode === 'Delivery' ? (
            <DeliveryIcon />
        ) : orderingMode === 'Dine-in' ? (
            <DinInIcon />
        ) : (
            <TakeAwayIcon />
        );
    };

    const paymentModeIcon = (paymentMode) => {
        return paymentMode === 'UPI' ? <UPIIcon /> : paymentMode === 'Cash' ? <CashIcon /> : <CardIcon />;
    };

    // const pastOrderDetails = [
    //     {
    //         customerName: 'Riddhi Shah',
    //         orderDate: '18 Nov 2022',
    //         orderType: 'Dine-In',
    //         orderStatus: ['Delivered'],
    //         itemOrdered: '12',
    //         orderAmount: '₹5,325.00/-',
    //         paymentMode: 'Cash',
    //     },
    //     {
    //         customerName: 'Riddhi Shah',
    //         orderDate: '18 Nov 2022',
    //         orderType: 'Dine-In',
    //         orderStatus: ['Cancelled', 'Refund initiated'],
    //         itemOrdered: '12',
    //         orderAmount: '₹5,325.00/-',
    //         paymentMode: 'UPI',
    //     },
    //     {
    //         customerName: 'Riddhi Shah',
    //         orderDate: '18 Nov 2022',
    //         orderType: 'Dine-In',
    //         orderStatus: ['Cancelled', 'Refund success'],
    //         itemOrdered: '12',
    //         orderAmount: '₹5,325.00/-',
    //         paymentMode: 'Card',
    //     },
    // ];
    const handleClickFailedOrder = (el) => {
        setShowPopup('orderDetails')
        setOrderDetail(el)
    }
    return (
        <>
            <ContentControllerSection
                setSelectedDates={setSelectedDates}
                selectedDates={selectedDates}
            />

            {isFetchingOrders ? (
                <TableLoader
                    hasSwitch={false}
                    columns={5}
                />
            ) : (
                <>
            {!isMobileScreen ? (
                <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                    <table className='w-full break-words'>
                        <thead>
                            <tr className='bg-neutral-50 uppercase shadow-innerShadow paragraph-overline-small text-neutral-700 pl-6 h-11'>
                                <th className='min-w-[234px] pl-6 text-left'>CUSTOMER NAME</th>
                                <th className='min-w-[174px] pl-6 text-left'>ORDER DATE</th>
                                <th className='min-w-[166px] pl-6 text-left'>ORDER TYPE</th>
                                <th className='min-w-[191px] pl-6 text-left'>ORDER STATUS</th>
                                <th className='min-w-[163px] pl-6 text-left'>ITEMS ORDERED</th>
                                <th className='min-w-[162px] pl-6 text-left'>ORDER AMOUNT</th>
                                <th className='min-w-[220px] pl-6 text-left'>PAYMENT MODE</th>
                            </tr>
                        </thead>
                        {orders.length > 0 ? (
                            <tbody>
                                {orders?.map((el, index) => {
                                    const orderIcon = orderTypeIcon(el.ordering_mode);
                                    const paymentIcon = paymentModeIcon(el.paymentMode);

                                    return (
                                        <tr
                                            key={index}
                                            className='even:bg-neutral-50 border-t first:border-none border-neutral-300 paragraph-small-regular h-[70px] justify-center'>
                                            <td
                                                className='pl-6 cursor-pointer text-primary-500 hover:underline'
                                                onClick={() => handleClickFailedOrder(el)}>
                                                {el.customer.name}
                                            </td>

                                            <td className='pl-6'>{el.status.placed_at}</td>

                                            <td className='pl-6'>
                                                <div className='flex flex-row items-center'>
                                                    {orderIcon}
                                                    <span className='pl-1'> {el.ordering_mode}</span>
                                                </div>
                                            </td>

                                            <td className='pl-6'>
                                                <OrderStatus
                                                    status={[
                                                        el?.status?.order_failed_at !== null
                                                            ? 'Failed'
                                                                    : 'Cancelled',
                                                    ]}
                                                />
                                                {el.refunds?.length !== 0 && (
                                                    <OrderStatus
                                                        status={[
                                                            el?.refunds?.[0]?.failed_at == null &&
                                                            el?.refunds?.[0]?.refunded_at == null &&
                                                            el?.refunds?.[0]?.initiated_at !== null
                                                                ? 'Refund initiated'
                                                                : el?.refunds?.[0]?.failed_at == null &&
                                                                  el?.refunds?.[0]?.refunded_at !== null ? 'refund success' :
                                                                  'Refund failed',
                                                        ]}
                                                    />
                                                )}
                                            </td>

                                            <td className='pl-6'>{el?.cart?.cart_items?.length}</td>
                                            <td className='pl-6'>{el.net_total}</td>

                                            <td className='pl-6'>
                                                <div className='flex flex-row items-center'>
                                                    {paymentIcon}
                                                    <span className='pl-1'>{el.payment_method.payment_method}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        ) : (
                                    <tr>
                                        <td colSpan={7}>
                                            <TableBlankComponentView previewText={'No Content Available'} />
                                        </td>
                                    </tr>
                        )}
                    </table>
                </div>
            ) : (
                ''
                    )}
                </>
            )}
            <div className='my-4'>
                <PaginationWithNumber
                    lastPage={paginationState.lastPage}
                    selectedPage={paginationState.currentPage}
                    setSelectedPage={(value) => {
                        setPaginationState({
                            currentPage: value,
                            lastPage: paginationState.lastPage,
                            perPage: paginationState.perPage,
                        });
                    }}
                    setResultPerPage={(value) => {
                        setPaginationState({
                            currentPage: paginationState.currentPage,
                            lastPage: paginationState.lastPage,
                            perPage: value,
                        });
                    }}
                    selectedResultPerpage={paginationState.perPage}
                />
            </div>
        </>
    );
}
