import React, { useEffect, useState } from 'react';
import { LargePrimaryButton } from '../../../../Components/Buttons/Button';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';
import { ReactComponent as LeftArrowIcon } from '../../../../Assets/chevron-down.svg';
import DeliveyEstimation from '../../Components/DeliveyEstimation';
import AvailableRiderDropdown from '../../Components/AvailableRiderDropdown';
import { IsMobileScreen } from '../../../../Constants/Constants';
import DropdownWithValueLabel from '../../../../Components/DropDown/DropdownWithValueLabel';
import { getEstimationTimeForRiderService, getQuotation, getRiders, assignRider } from '../../Helper/functions';

export default function AssignRiderPopup(props) {
    const {
        handleClickClose,
        thirdPartyRiderSettings,
        inHouseRiderEnabled,
        orderId,
        hasRiderAssigned,
        setHasRiderAssigned,
        onConfirmAccept,
        selectedOrder,
        fullFillStatus,
        setAssignRider,
        setFullFillStatus,
    } = props;
    const [selectedDeliveryPartner, setSelectedDeliveryPartner] = useState(null);
    const [selectedRider, setSelectedRider] = useState(null);
    const [riderList, setRiderList] = useState([]);
    const [serviceDetail, setServiceDetail] = useState([]);
    const isMobileScreen = IsMobileScreen();
    const [pidgeRider, setPidgeRider] = useState(false);
    const [pidgeServiceDetail, setPidgeServiceDetail] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isDisable , setIsDisable] = useState(false)
    const [retryAssignRider , setReadyAssignRider] = useState(false)
    const [deliveryServices, setDeliveryServices] = useState([
        inHouseRiderEnabled && { label: 'Bolt Riders', value: 'bolt' },
    ]);
    const [pidgeData, setPidgeData] = useState('');
    useEffect(() => {
        const fetchDeliveryServices = async () => {
            try {
                const response = await getQuotation({ orderId });
                if (response?.service_details) {
                    const services = response.service_details.map((service) => ({
                        label: service?.network_name,
                        value: service?.network_name,
                    }));
                    setServiceDetail(services);
                    setPidgeRider(true);
                    setPidgeServiceDetail(response?.service_details);
                    setRiderList([]);
                    setIsLoading(false);
                    const riders = await getRiders();
                    setRiderList(
                        riders.map((item) => ({
                            riderName: `${item.first_name} ${item.middle_name} ${item.last_name}`,
                            active: item.active,
                            mobileNumber: item.mobile_number,
                            riderId: item.rider_id,
                            online: item.online,
                            deliveryRate: item.delivery_rate,
                            age: item.age,
                            extraDeliveryRate: item.extra_delivery_rate,
                            normalCharge: item.normal_service_radius,
                        }))
                    );
                    setDeliveryServices((prevState) => [...prevState, ...services]);
                } else {
                    const riders = await getRiders();
                    const riderServices = [
                        thirdPartyRiderSettings.dunzo_integration_available && {
                            label: thirdPartyRiderSettings?.dunzo_request?.third_party_service,
                            value: thirdPartyRiderSettings?.dunzo_request?.third_party_service,
                        },
                        thirdPartyRiderSettings.elt_integration_available && { label: 'elt', value: 'elt' },
                        thirdPartyRiderSettings.is_porter_integration_enabled && {
                            label: thirdPartyRiderSettings?.porter_request?.third_party_service,
                            value: thirdPartyRiderSettings?.porter_request?.third_party_service,
                        },
                        thirdPartyRiderSettings.is_shadowfax_integration_enabled && {
                            label: thirdPartyRiderSettings?.shadowfax_request?.third_party_service,
                            value: thirdPartyRiderSettings?.shadowfax_request?.third_party_service,
                        },
                        thirdPartyRiderSettings.wefast_integration_available && {
                            label: thirdPartyRiderSettings?.wefast_request?.third_party_service,
                            value: thirdPartyRiderSettings?.wefast_request?.third_party_service,
                        },
                    ].filter(Boolean);
                    setDeliveryServices((prevState) => [...prevState, ...riderServices]); 
                    setIsLoading(false)
                    setRiderList(
                        riders.map((item) => ({
                            riderName: `${item.first_name} ${item.middle_name} ${item.last_name}`,
                            active: item.active,
                            mobileNumber: item.mobile_number,
                            riderId: item.rider_id,
                            online: item.online,
                            deliveryRate: item.delivery_rate,
                            age: item.age,
                            extraDeliveryRate: item.extra_delivery_rate,
                            normalCharge: item.normal_service_radius,
                        }))
                    );
                }
            } catch (error) {
                console.error('Failed to fetch delivery services:', error);
            }
        };

        fetchDeliveryServices();
    }, [orderId, inHouseRiderEnabled, thirdPartyRiderSettings]);

    const onClose = () => {
        handleClickClose();
        setSelectedDeliveryPartner(null);
        setRiderList([]);
        setSelectedRider(null);
        setPidgeData(null)
    };
    const onSelectRider = async (service) => {
        setSelectedDeliveryPartner(service);
        if (pidgeRider) {
            const selectedServiceDetail = pidgeServiceDetail.find((detail) => detail.network_name === service.value);
            setPidgeData(selectedServiceDetail);
            if (service.value === 'bolt') {
                const riders = await getRiders();
                setRiderList(
                    riders.map((item) => ({
                        riderName: `${item.first_name} ${item.middle_name} ${item.last_name}`,
                        active: item.active,
                        mobileNumber: item.mobile_number,
                        riderId: item.rider_id,
                        online: item.online,
                        deliveryRate: item.delivery_rate,
                        age: item.age,
                        extraDeliveryRate: item.extra_delivery_rate,
                        normalCharge: item.normal_service_radius,
                    }))
                );
            } else if (selectedServiceDetail) {
                setServiceDetail([selectedServiceDetail]);
            } else {
                console.error('Service detail not found for the selected service');
            }
        } else {
            if (service.value === 'bolt') {
                const riders = await getRiders();
                setRiderList(
                    riders.map((item) => ({
                        riderName: `${item.first_name} ${item.middle_name} ${item.last_name}`,
                        active: item.active,
                        mobileNumber: item.mobile_number,
                        riderId: item.rider_id,
                        online: item.online,
                        deliveryRate: item.delivery_rate,
                        age: item.age,
                        extraDeliveryRate: item.extra_delivery_rate,
                        normalCharge: item.normal_service_radius,
                    }))
                );
            } else {
                const response = await getEstimationTimeForRiderService({ serviceName: service.value, orderId });
                if (response?.success) {
                    setServiceDetail(response); 
                } else {
                    alert(response.errorMessage);
                }
            }
        }
    };
    const handleClickAssignRider = async () => {
        setIsDisable(true); 
        try {
            const assignRiders = await assignRider(
                pidgeData,
                orderId,
                handleClickClose,
                setHasRiderAssigned,
                selectedOrder,
                fullFillStatus,
                setFullFillStatus
            );
            if (assignRiders?.success) {
                setAssignRider(true);
            }
        } catch (error) {
            console.error('Failed to assign rider:', error);
            setReadyAssignRider(true)
        } finally {
            setIsDisable(false); 
        }
    };
    return (
        <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center overflow-auto md:bg-white md:relative p-4'>
            <div
                className={`bg-white w-[460px] rounded-xl px-8 py-6 m-auto overflow-auto [&::-webkit-scrollbar]:hidden md:px-0 md:py-0 md:w-full md:rounded-none`}>
                {isMobileScreen && (
                    <div
                        className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                        onClick={onClose}>
                        <LeftArrowIcon className='rotate-90' />
                        <span className='ml-1'>Back to Orders</span>
                    </div>
                )}

                <div className='flex flex-row justify-between mb-4 pb-2'>
                    <div className=''>
                        <div className='paragraph-large-medium md:paragraph-medium-medium'>Assign rider</div>
                        <span className='paragraph-medium-italic text-neutral-500'>
                            Select rider to deliver this order
                        </span>
                    </div>
                    <span
                        className='cursor-pointer md:hidden'
                        onClick={handleClickClose}>
                        <CloseIcon
                            height={24}
                            width={24}
                        />
                    </span>
                </div>

                <div className='mb-4'>
                    <DropdownWithValueLabel
                        menuItems={deliveryServices}
                        label='Select delivery partner'
                        placeholder='Select delivery partner'
                        setSelected={(service) => onSelectRider(service)}
                        fixedHeight='h-[100px]'
                        isLoading={isLoading}
                    />
                </div>

                {selectedDeliveryPartner?.value === 'bolt' && (
                    <div className=''>
                        <AvailableRiderDropdown
                            menuItems={riderList}
                            label='Select rider'
                            placeholder='Select rider'
                            setSelectedItemFromDropDown={setSelectedRider}
                        />
                    </div>
                )}

                {((selectedDeliveryPartner?.value !== 'bolt' && selectedDeliveryPartner) || selectedRider) && (
                    <DeliveyEstimation
                        selectedDeliveryPartner={selectedDeliveryPartner}
                        selectedRider={selectedRider}
                        serviceDetail={serviceDetail}
                        pidgeData={pidgeData}
                    />
                )}

                <div
                    onClick={!isDisable ? handleClickAssignRider : null} 
                    className='mt-12 md:fixed md:bottom-0 md:w-full md:pr-4 md:pb-2 md:pt-4 md:shadow-dropShadow md:-ml-4 md:pl-4'>
                    <LargePrimaryButton
                        label='Assign rider'
                        disabled={isDisable}
                        isLoading={isDisable}
                        retryAssignRider = {retryAssignRider}
                    />
                </div>
            </div>
        </div>
    );
}
