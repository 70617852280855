import React from 'react';
import CategoryCard from '../Components/CategoryCard';

export default function CategoriesSection({ structuredCategories, setSelectedCategory }) {
    const handleCategoryClick = (item) => {
        console.log(`item ==>`,  item);
        setSelectedCategory(item.id);
    };
    return (
        <>
            <div
                className='sticky z-1 bottom-0 pl-6 pr-[26px] pageContent:mb-0 h-fit mt-auto pageContent:inline-grid'
                style={{
                    boxShadow: '0px -4px 15px -3px rgba(16, 24, 40, 0.1), 0px -4px 6px -4px rgba(16, 24, 40, 0.1)',
                }}>
                <div className='max-w-[844px] w-full flex flex-col overflow-auto scrollbar-style pb-3'>
                    <div className='flex flex-row mt-4'>
                        {structuredCategories.map(
                            (el, index) =>
                                index % 2 === 0 && (
                                    <div
                                        className='mr-5'
                                        key={index}
                                        onClick={() => handleCategoryClick(el)}>
                                        <CategoryCard {...el} />
                                    </div>
                                )
                        )}
                    </div>

                    <div className='flex flex-row mt-4'>
                        {structuredCategories.map(
                            (el, index) =>
                                index % 2 !== 0 && (
                                    <div
                                        className='mr-5'
                                        key={index}
                                        onClick={() => handleCategoryClick(el)}>
                                        <CategoryCard {...el} />
                                    </div>
                                )
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
