import React, { useEffect, useState } from 'react';
import { ReactComponent as SwiggyIcon } from '../../../../Assets/swiggy.svg';
import ListviewOrder from '../ListviewOrder';
import { useWindowSize } from '@uidotdev/usehooks';
import { IsMobileScreen } from '../../../../Constants/Constants';
import ListViewOfKOT from '../ListViewOfKOT/ListViewOfKOT';
import TableSection from './TableSection';
import { fetchOrderDetail } from '../../Helper/functions';

export default function ListViewOfKOTSection(props) {
    const {
        setShowPopup,
        orders,
        paginationState,
        setPaginationState,
        selectedOrder,
        setSelectedOrder,
        onReady,
        selectedOrderDetail,
        handleClickClose,
        setCancelRider,
    } = props;
    console.log('ordersrqt <====>', orders);

    console.log('selectedOrderDetail <====>', selectedOrderDetail);
    const isMobileScreen = IsMobileScreen();
    const [orderItems , setOrderItems] = useState('')
    const screenWidth = useWindowSize().width;
    const [isLoading , setIsLoading] = useState(false)

 useEffect(() => {
     if (orders?.length > 0) {
         const fetchAllOrders = async () => {
            setIsLoading(true)
             try {
                 // Map through each order and call fetchOrderDetail for each order_id
                 const allOrderDetails = await Promise.all(
                     orders.map(async (order) => {
                         const response = await fetchOrderDetail(order.order_id);
                         return { ...order, details: response }; 
                     })
                 );

                 console.log(allOrderDetails, 'All Orders with Details');
                 if(allOrderDetails){
                    setIsLoading(false)
                 }
                 // Set the state with the fetched order details
                 setOrderItems(allOrderDetails);
             } catch (error) {
                 console.error('Error fetching order details:', error);
             }
         };

         fetchAllOrders();
     }
 }, [orders]);

    console.log(orderItems , "orderItem")
    return (
        <>
            {!isMobileScreen && (
                <div className='flex flex-row justify-between mt-6 lg:mt-4'>
                    <TableSection
                        orders={orders}
                        setShowPopup={setShowPopup}
                        paginationState={paginationState}
                        setPaginationState={setPaginationState}
                        setSelectedOrder={setSelectedOrder}
                        selectedOrder={selectedOrder}
                    />

                    {screenWidth > 1280 && selectedOrder.order_id && (
                        <div className='max-w-[518px] w-full pageContent:min-w-min pageContent:max-w-[450px]'>
                            <div className='w-full pt-3 border border-neutral-300 rounded-xl'>
                                <ListViewOfKOT
                                    orderDetail={selectedOrderDetail}
                                    setShowPopup={setShowPopup}
                                    onReady={onReady}
                                    orderItems={orderItems}
                                    setCancelRider={setCancelRider}
                                    handleClickClose={handleClickClose}
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}

            {isMobileScreen && (
                <div className='md:mt-4'>
                    {orderItems?.length &&
                        orderItems?.map((el, index) => (
                            <div
                                className=''
                                key={index}>
                                <ListviewOrder
                                    {...el}
                                    el={el}
                                    handleClickOrdersDetails={() => setShowPopup('customerDetails')}
                                />
                            </div>
                        ))}
                </div>
            )}
        </>
    );
}
