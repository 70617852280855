import React from 'react';
import { DropDownTabs } from '../../../../Components/DropDown/DropDownTabs';
import { ReactComponent as LeftArrow } from '../../../../Assets/chevron-down.svg';
import { IsIpadScreen, IsMobileScreen } from '../../../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';
import { useNavigate, useParams } from 'react-router-dom';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../../Components/Buttons/Button';
import { MultipleTab } from '../../../../Components/Tabs/Tabs';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { addCategory, editCategory } from '../../functions';
import { tabsComponent } from '../constants';

export default function Layout(props) {
    const { children, showHeaderTab, setCurrentPage } = props;

    const { categoryTab, editId } = useParams();

    const isMobileScreen = IsMobileScreen();
    const screenSize = useWindowSize();
    const isIpadScreen = IsIpadScreen();
    const navigate = useNavigate();

    const isEditMode = !!editId;

    const isLastSection = categoryTab === 'advance-setting';

    const tabs = [
        {
            item: 'Basic details',
            onClick: () => {
                navigate(`/menu/${isEditMode ? `edit-category/${editId}` : 'add-category'}/basic-details`);
                setCurrentPage('basic-details');
            },
            link: 'basic-details',
            formState: 'details',
        },
        {
            item: 'Expose Category',
            onClick: () => {
                navigate(`/menu/${isEditMode ? `edit-category/${editId}` : 'add-category'}/expose-category`);
                setCurrentPage('expose-category');
            },
            link: 'expose-category',
            formState: 'exposeCategory',
        },
        {
            item: 'Category timings',
            onClick: () => {
                navigate(`/menu/${isEditMode ? `edit-category/${editId}` : 'add-category'}/category-timings`);
                setCurrentPage('category-timings');
            },
            link: 'category-timings',
            formState: 'timings',
        },
        {
            item: 'Advance settings',
            onClick: () => {
                navigate(`/menu/${isEditMode ? `edit-category/${editId}` : 'add-category'}/advance-setting`);
                setCurrentPage('advance-setting');
            },
            link: 'advance-setting',
            formState: 'advanceSetting',
        },
    ];

    const {
        handleSubmit,
        formState: { errors },
        setError,
        watch,
    } = useFormContext();

    const orderingMode = useSelector((state) => state.outlet.orderingModes);

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const availableLanguages = useSelector((state) => state.langauge.availableLanguages);

    const createCategory = async (params) => {
        try {
            const response = await addCategory(selectedRestaurantId, params);

            if (response?.response?.data?.errors?.internal_name) {
                setError('internalName', { message: response?.response?.data?.errors?.internal_name });
            }

            if (response?.response?.data?.errors?.['translations.title']) {
                setError(`title.${availableLanguages[0].id}`, {
                    message: response?.response?.data?.errors?.['translations.title'],
                });
            }

            if (response.success) {
                navigate('/menu/catalogue');
            }
        } catch (error) {
        }
    };

    const updateCategory = async (params) => {
        try {
            const response = await editCategory(selectedRestaurantId, params, editId);


            response?.response?.data?.errors?.internal_name &&
                setError('internalName', { message: response?.response?.data?.errors?.internal_name });

            if (response.success) {
                navigate('/menu/catalogue');
            }
        } catch (error) {
        }
    };

    const onSave = (data) => {
        if (_.isEmpty(watch('imageDetails'))) {
            setError('imageDetails', { message: 'Please select image' });
            return;
        }

        goNextSection(data);
    };

    const onDiscard = () => {
        navigate('/menu/catalogue');
    };

    const findHasError = (tab) => {
        return Object.keys(errors)?.some((el) => tabsComponent?.[tab?.formState]?.includes(el));
    };

    const sections = ['basic-details', 'expose-category', 'category-timings', 'advance-setting'];

    const formatData = (data) => {
        const translations = {
            title: {},
            description: {},
        };

        data?.title?.forEach((value, index) => {
            if (value !== null) {
                translations.title[index] = value;
            }
        });

        data?.description?.forEach((value, index) => {
            if (value !== null) {
                translations.description[index] = value;
            }
        });

        const ordering_modes = data?.exposeDish?.map((el) => ({
            restaurant_ordering_mode_id: el.id,
            restaurant_ordering_mode_name: el.display_name,
        }));

        const exposeDishIds = data?.exposeDish?.map((dish) => dish.id);

        const delete_ordering_modes = orderingMode
            .filter((mode) => !exposeDishIds.includes(mode.id))
            .map((mode) => mode.id);

        const formattedData = {
            category_image_id: data?.imageDetails?.id,
            apply_mask: data?.advanceSetting?.mask?.value,
            hidden: data?.advanceSetting?.hideCategory?.value,
            hide_category_tile_details: data?.advanceSetting?.tileDetails?.value,
            internal_name: data?.internalName,
            translations,
            // parent_id: null,
            theme: data?.advanceSetting?.displayTheme?.value,
            ordering_modes,
            delete_ordering_modes,
            tile_details_position: data?.advanceSetting?.tilePosition?.value,
            gradient_position: data?.advanceSetting?.tilePosition?.value,
        };

        return formattedData;
    };

    const goNextSection = (data) => {
        const currentIndex = sections.indexOf(categoryTab);

        const nextSection = sections[currentIndex + 1];

        const editCategoryRoute = `/menu/edit-category/${editId}/${nextSection}`;

        const route = isEditMode ? editCategoryRoute : `/menu/add-category/${nextSection}`;
        if (nextSection) {
            navigate(route);
            setCurrentPage(nextSection);
        } else {
            isEditMode ? updateCategory(formatData(data)) : createCategory(formatData(data));
        }
    };

    return (
        <>
            <div className='bg-[#fafafa]'>
                <div
                    className={`px-8 lg:px-4 w-full max-w-[1336px] mx-auto bg-white relative md:max-w-full ${
                        !showHeaderTab ? 'md:px-0' : 'px-3'
                    }`}
                    style={{ height: isIpadScreen ? screenSize.height - 132 : screenSize.height - 147 }}>
                    {isMobileScreen && showHeaderTab && (
                        <div className=''>
                            <div
                                className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer md:px-1 md:pt-4'
                                onClick={() => navigate('/menu')}>
                                <LeftArrow className='rotate-90' />
                                <span className='ml-1'>Back to catalogue</span>
                            </div>

                            <div className='block mb-4 pb-4 border-b border-neutral-300 mx-1'>
                                <DropDownTabs menuItems={tabs} />
                            </div>
                        </div>
                    )}

                    <div className='flex flex-row md:block'>
                        {!isMobileScreen && (
                            <div
                                className='flex flex-col mt-4 pr-6 mr-4 border-r min-w-[197px] border-neutral-300 overflow-auto [&::-webkit-scrollbar]:hidden lg:pr-4 lg:mr-3 lg:min-w-[190px]'
                                style={{ height: isIpadScreen ? screenSize.height - 148 : screenSize.height - 163 }}>
                                {tabs.map((el, index) => {
                                    const hasError = findHasError(el);

                                    return (
                                        <div
                                            className='lg:max-w-[173px] w-full mb-4'
                                            key={index}>
                                            <MultipleTab
                                                hasError={hasError}
                                                label={el.item}
                                                index={index}
                                                onClick={el.onClick}
                                                isActive={categoryTab === el.link}
                                                minWidth='min-w-[173px]'
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )}

                        <div
                            className='mt-4 w-full pl-1 md:pr-1 md:mt-0 overflow-auto md:pb-20 md:overflow-visible'
                            style={{ height: screenSize.width > 767 ? screenSize.height - 165 : 'auto' }}>
                            {children}
                        </div>
                    </div>
                </div>

                <div className='sticky z-[49] md:z-[8] group-[.undock-left-panel]:z-[8] md:fixed md:justify-center md:border-none md:py-0 md:pb-1 md:pt-2 md:shadow-dropShadow left-0 right-0 bottom-0 flex px-8 lg:px-4 flex-row justify-end w-full max-w-[1336px] mx-auto bg-white py-6 border-t border-neutral-300 lg:py-4'>
                    <div
                        className='min-w-[196px] mr-5 md:w-1/2 md:mr-[7.5px] md:min-w-0'
                        onClick={onDiscard}>
                        <LargeDestructiveButton label='Discard' />
                    </div>

                    <div
                        className='min-w-[196px] md:w-1/2 md:ml-[7.5px] md:min-w-0'
                        onClick={handleSubmit(onSave)}>
                        <LargePrimaryButton
                            label={isLastSection ? 'Save' : 'Next'}
                            isDefault={false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
