import React, { useState } from 'react';
import { ReactComponent as Filter } from '../../../Assets/filter.svg';
import { ReactComponent as Export } from '../../../Assets/export.svg';
import { ReactComponent as AddIcon } from '../../../Assets/add.svg';
import { ReactComponent as EditIcon } from '../../../Assets/edit.svg';
import { ReactComponent as SelectIcon } from '../../../Assets/select.svg';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';

import CalenderField from '../../../Components/Calender/CalenderField';
import DropdownWithValueLabel from '../../../Components/DropDown/DropdownWithValueLabel';
import Filters from './Filters';
import RiderOrderFilter from './RiderOrderFilter';
import { IsMobileScreen } from '../../../Constants/Constants';
import { LargeDestructiveButton, LargePrimaryButton, LargeTertiaryButton } from '../../../Components/Buttons/Button';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import APIV3 from '../../../api/axios/APIV3';
import { useParams } from 'react-router-dom';

const Header = (props) => {
    const {
        handleClickAddRider,
        hiddenElement,
        removeMargin,
        HeaderStyle,
        handleClickEditTab,
        setSelectedDates,
        selectedDates,
        setAppliedFilter,
        isRiderOrderFilter,
        setAppliedRiderOrderFilter,
        handleBulkSelect,
        showCancelDelete,
        handleClickCancel,
        handleClickDelete,
        isDisable,
        ratingArray,
        isEditTable,
        page,
        appliedFilter,
        appliedRiderOrderFilter,
    } = props;

    const { id } = useParams();

    const [showFilters, setShowFilters] = useState(false);

    const handleClickFilter = () => {
        setShowFilters(!showFilters);
    };

    const [isExportDataDisable, setIsExportDataDisable] = useState(false);

    const isMobileScreen = IsMobileScreen();

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    let data;

    const handleClickExportData = async () => {
        const [from_date, to_date] = selectedDates.sort((a, b) => a - b);

        data = {
            start_date: formatDate(from_date),
            end_date: formatDate(to_date),
        };

        if (page === 'rider') {
            data.type = 'rider_list_report';
            data.outlet_id = selectedOutletId;
            data.rating = { from: ratingArray[0]?.toFixed(1), to: ratingArray[1]?.toFixed(1) };
        }

        if (page === 'rider-details') {
            data.type = 'rider_orders_list_report';
            data.payment_mode = appliedRiderOrderFilter;
            data.rider_id = id;
            if (ratingArray) {
                data.rating = { from: ratingArray[0]?.toFixed(1), to: ratingArray[1]?.toFixed(1) };
            }
        }

        try {
            setIsExportDataDisable(true);
            const response = await APIV3.post(`export-report`, data);
            if (response.success === true) {
                setIsExportDataDisable(false);
            } else {
                setIsExportDataDisable(false);
            }
        } catch (error) {
            console.log('error <====>', error);
        }
    };

    return (
        <>
            <div
                className={`flex flex-row justify-between border-b border-neutral-300 pb-4 lg:mb-[7px] md:mb-4 md:block ${HeaderStyle}`}>
                <div className='flex flex-row md:justify-between'>
                    {/* <CalenderField
                        setSelectedDates={setSelectedDates}
                        selectedDates={selectedDates}
                    /> */}
                    {isMobileScreen &&
                        (showCancelDelete ? (
                            <div
                                className='max-w-[120px] w-full ml-4 lg:ml-2 md:max-w-[56px] mobile:md:max-w-[45px]'
                                onClick={handleBulkSelect}>
                                <LargePrimaryButton
                                    label='Bulk select'
                                    hideLabel='md:hidden'
                                    leftIconDefault={<SelectIcon stroke='#ffffff' />}
                                    leftIconClick={<SelectIcon stroke='#C4BEED' />}
                                />
                            </div>
                        ) : (
                            <div className='flex flex-row items-center md:w-full'>
                                <div
                                    className='max-w-[120px] w-full ml-4 lg:ml-2 md:max-w-[56px] mobile:md:max-w-[45px]'
                                    onClick={handleClickCancel}>
                                    <LargeTertiaryButton
                                        isDefault={false}
                                        label='Cancel'
                                        hideLabel='lg:hidden'
                                        leftIconDefault={<CloseIcon stroke='#FFFFFF' />}
                                        leftIconClick={<CloseIcon stroke='#C4BEED' />}
                                    />
                                </div>

                                <div
                                    className='max-w-[120px] w-full ml-4 lg:ml-2 md:max-w-[56px] mobile:md:max-w-[45px]'
                                    onClick={handleClickDelete}>
                                    <LargeDestructiveButton
                                        label='Delete selected'
                                        leftIconDefault={<SelectIcon stroke='#FFFFFF' />}
                                        leftIconClick={<SelectIcon stroke='#D7EDEB' />}
                                        hideLabel='lg:hidden'
                                        disabled={isDisable}
                                    />
                                </div>
                            </div>
                        ))}
                    <div
                        className='max-w-[120px] w-full ml-4 lg:ml-2 md:max-w-[56px] mobile:md:max-w-[45px]'
                        onClick={handleClickFilter}>
                        <LargePrimaryButton
                            label='Filters'
                            hideLabel='md:hidden'
                            leftIconDefault={<Filter fill='#ffffff' />}
                            leftIconClick={<Filter fill='#C4BEED' />}
                        />
                    </div>
                </div>
                <div className='flex flex-row md:justify-between md:mt-4'>
                    {/* <div
                        className={`mx-4 md:w-1/2 md:ml-0 md:mr-2 mobile:mr-1 ${removeMargin}`}
                        onClick={() => handleClickExportData()}>
                        <LargePrimaryButton
                            label='Export data'
                            leftIconDefault={<Export stroke='#FFFFFF' />}
                            leftIconClick={<Export stroke='#C4BEED' />}
                            hideLabel='lg:hidden md:block'
                            isClicked={isExportDataDisable}
                        />
                    </div> */}
                    <div
                        className={`md:w-1/2 md:ml-4 mobile:ml-1 mr-4 cursor-pointer ${hiddenElement}`}
                        onClick={handleClickEditTab}>
                        <LargePrimaryButton
                            isDefault={false}
                            label='Edit rider'
                            leftIconDefault={<EditIcon stroke='#FFFFFF' />}
                            leftIconClick={<EditIcon stroke='#C4BEED' />}
                            hideLabel='lg:hidden md:block'
                            isClicked={isEditTable}
                        />
                    </div>
                    <div
                        className={`md:w-1/2 md:ml-2 mobile:ml-1 cursor-pointer ${hiddenElement}`}
                        onClick={handleClickAddRider}>
                        <LargePrimaryButton
                            isDefault={false}
                            label='Add rider'
                            leftIconDefault={<AddIcon stroke='#FFFFFF' />}
                            leftIconClick={<AddIcon stroke='#C4BEED' />}
                            hideLabel='lg:hidden md:block'
                        />
                    </div>
                </div>
            </div>
            {showFilters ? (
                isRiderOrderFilter ? (
                    <RiderOrderFilter
                        setAppliedRiderOrderFilter={setAppliedRiderOrderFilter}
                        setAppliedFilter={setAppliedFilter}
                    />
                ) : (
                    <Filters setAppliedFilter={setAppliedFilter} />
                )
            ) : null}
        </>
    );
};

export default Header;
