import * as types from '../types/outlet';

const selectedOutletId = localStorage.getItem('selectedOutletId')
    ? JSON.parse(localStorage.getItem('selectedOutletId'))
    : null;

const initialState = {
    outletList: [],
    selectedOutletId,
    selectedOutlet: null,
    orderingModes: [],
    outletOrderingModes: [],
    categories: [],
    items: [],
    categoryWithItems: [],
};
// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_OUTLET_LIST:
            return {
                ...state,
                outletList: action.payload.outletList,
            };
        case types.SET_SELECTED_OUTLET_ID:
            return {
                ...state,
                selectedOutletId: action.payload.selectedOutletId,
            };
        case types.SET_SELECTED_OUTLET:
            return {
                ...state,
                selectedOutlet: action.payload.selectedOutlet,
            };
        case types.SET_ORDERING_MODES:
            return {
                ...state,
                orderingModes: action.payload,
            };
        case types.SET_OUTLET_ORDERING_MODES:
            return {
                ...state,
                outletOrderingModes: action.payload,
            };
        case types.SET_OUTLET_MENU:
            return {
                ...state,
                categories: action.payload.categories,
                items: action.payload.items,
            };
        case types.SET_CATEGORY_WITH_ITEMS:
            return {
                ...state,
                categoryWithItems: action.payload,
            };
        default:
            return state;
    }
};
