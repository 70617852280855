import React from 'react'
import ContentLoader from 'react-content-loader';

export default function DetailLoader() {
  return (
      <>
          <ContentLoader
              width='100%'
              height='100%'
              className='h-[30px] w-full pt-2'>
              <rect
                  x='0'
                  y='0'
                  rx='4'
                  ry='4'
                  width='60%'
                  height='24'
              />
          </ContentLoader>
      </>
  );
}
