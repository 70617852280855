import React, { useEffect, useState } from 'react';
import TitleDescription from '../../../../../../Components/TitleDescription/TitleDescription';
import DropdownPagination from '../../../../../../Components/DropDown/DropdownPagination';
import { Controller, useFormContext } from 'react-hook-form';
import { fetchCategoryList, fetchDishList } from '../../../../functions';
import { useSelector } from 'react-redux';
import DropdownCheckboxPagination from '../../../../../../Components/DropDown/DropdownCheckboxPagination';

export default function DishCategorySelection() {
    const { control, watch, setValue } = useFormContext();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [categories, setCategories] = useState([]);

    const initialPaginationState = { page: 1, per_page: 10, has_more_pages: null };

    const [categoryPaginationState, setCategoryPaginationState] = useState(initialPaginationState);

    const fetchCategory = async (params) => {
        try {
            const category = await fetchCategoryList(selectedRestaurantId, params);

            const formateCategory = category.data.map((el) => ({ label: el.internal_name, value: el }));

            setCategories((prevState) => [...prevState, ...formateCategory]);

            setCategoryPaginationState((prevState) => ({
                ...prevState,
                page: params.page,
                has_more_pages: category.has_more_pages,
            }));

            return true;
        } catch (error) {
            console.log('error : ', error);
        }
    };

    const [dishPagination, setDishPagination] = useState(initialPaginationState);

    const fetchDish = async (params) => {
        try {
            const dish = await fetchDishList(
                selectedRestaurantId,
                params,
                watch('upSellDish.category')?.value?.category_id
            );

            const selectedDishIds = selectedDish?.map((dish) => dish.value.item_id) ?? [];

            const formateCategory =
                dish?.data?.map((el) => ({
                    label: el.internal_name,
                    value: el,
                    isSelected: selectedDishIds?.includes(el.item_id),
                })) ?? [];

            params.page === 1 ? setDish(formateCategory) : setDish((prevState) => [...prevState, ...formateCategory]);

            setDishPagination((prevState) => ({
                ...prevState,
                page: params.page,
                has_more_pages: dish.has_more_pages,
            }));

            return true;
        } catch (error) {
            console.log('error : ', error);
        }
    };

    useEffect(() => {
        fetchCategory(initialPaginationState);
    }, []);

    useEffect(() => {
        !!watch('upSellDish.category') && fetchDish(initialPaginationState);
    }, [watch('upSellDish.category')]);

    const [dish, setDish] = useState([]);

    const selectedDish = watch('upSelling.selectedDish') ?? [];

    const handleSelectDish = (isChecked, dish) => {
        setDish((prevState) => {
            return prevState?.map((el) => {
                if (el?.value?.item_id === dish?.value?.item_id) {
                    return {
                        ...el,
                        isSelected: isChecked,
                    };
                }

                return el;
            });
        });

        const updateDish = isChecked
            ? [...selectedDish, { ...dish, category: watch('upSellDish.category') }]
            : selectedDish?.filter((el) => el.value?.item_id !== dish?.value?.item_id);

        setValue('upSelling.selectedDish', updateDish);
    };

    return (
        <>
            <TitleDescription
                className='mb-6'
                title='Up-selling dishes'
                description='You can add multiple up-selling dishes to your current dish to offer up-gradation or include add-ons to the dish that customers are ordering. '
            />

            <div className='flex flex-row mb-6 gap-3'>
                <div className='w-1/2 relative'>
                    <Controller
                        render={({ field }) => (
                            <DropdownPagination
                                label='(Select category)'
                                labelStyle='paragraph-medium-italic text-neutral-500'
                                placeholder='Select category'
                                shadow='shadow-smallDropDownShadow'
                                menuItems={categories}
                                fixedHeight='h-[200px]'
                                fetchMenuItems={fetchCategory}
                                setPaginationState={setCategoryPaginationState}
                                paginationState={categoryPaginationState}
                                selectedItem={field.value}
                                setSelected={field.onChange}
                                labelPaddingB='pb-2'
                                height='h-[52px]'
                            />
                        )}
                        name='upSellDish.category'
                        control={control}
                    />
                </div>

                <div className='w-1/2 relative'>
                    <DropdownCheckboxPagination
                        disabled={!watch('upSellDish.category')}
                        label='(Select dish)'
                        labelStyle={`paragraph-medium-italic ${!watch('upSellDish.category') ? 'text-neutral-300' : 'text-neutral-500'}`}
                        placeholder='Select dish'
                        shadow='shadow-smallDropDownShadow'
                        menuItems={dish}
                        fixedHeight='h-[200px]'
                        fetchMenuItems={fetchDish}
                        setPaginationState={setDishPagination}
                        paginationState={dishPagination}
                        labelPaddingB='pb-2'
                        height='h-[52px]'
                        handleChangeCheck={handleSelectDish}
                    />
                </div>
            </div>
        </>
    );
}
