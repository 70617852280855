import React from 'react';

import { ReactComponent as DelieryIcon } from '../../../Assets/riders.svg';
import { ReactComponent as DininIcon } from '../../../Assets/dine-in.svg';
import { ReactComponent as TakeAwayIcon } from '../../../Assets/orders.svg';
import { orderStatus } from '../Helper/functions';

export default function ListviewOrder(props) {
    const { ordering_mode_details, customer, handleClickOrdersDetails  , el } = props;

    const orderStatuStyle = (status) => {
        return status === 'Prepared'
            ? 'text-tertiary-800 bg-tertiary-50 border-tertiary-800'
            : status === 'Preparing'
              ? 'text-secondary-800 bg-secondary-50 border-secondary-800'
              : 'text-primary-500 bg-primary-50 border-primary-500';
    };

    const statusStyle = orderStatuStyle(orderStatus(props));

    const modeIcon =
        ordering_mode_details.type === 'Delivery' ? (
            <DelieryIcon stroke='#FFFFFF' />
        ) : ordering_mode_details.type === 'Dine-in' ? (
            <DininIcon stroke='#FFFFFF' />
        ) : (
            <TakeAwayIcon stroke='#FFFFFF' />
        );

    const iconBG =
        ordering_mode_details.type === 'Delivery'
            ? 'bg-tertiary-800'
            : ordering_mode_details.type === 'Dine-in'
              ? 'bg-primary-500'
              : 'bg-secondary-700';

    return (
        <>
            <div
                className='w-full px-4 mobile:px-3 py-[14px] mobile:py-2.5 mb-2 border border-neutral-300 rounded-md cursor-pointer'
                onClick={handleClickOrdersDetails}>
                <div className='flex flex-row items-center justify-between'>
                    <div className='flex items-center'>
                        <div className={`flex min-w-[40px] h-10 rounded-lg ${iconBG}`}>
                            <span className='m-auto'>{modeIcon}</span>
                        </div>
                        <div className='pl-2'>
                            <span className='hidden md:block uppercase paragraph-overline-small mobile:text-[11px] mobile:leading-none'>
                                mode &amp; customer name:
                            </span>
                            <span>{el?.customer?.name}</span>
                        </div>
                    </div>
                    <span className={`paragraph-small-medium px-2 py-1 border rounded ${statusStyle}`}>
                        {orderStatus(props)}
                    </span>
                </div>
            </div>
        </>
    );
}
