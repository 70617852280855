import React from 'react';
import { ReactComponent as DininIcon } from '../../../Assets/dine-in.svg';
import { ReactComponent as DeliveryIcon } from '../../../Assets/riders.svg';
import { ReactComponent as TakeAwayIcon } from '../../../Assets/orders.svg';
import { ReactComponent as CardIcon } from '../../../Assets/card.svg';
import { ReactComponent as CashIcon } from '../../../Assets/cash.svg';
import { ReactComponent as UpiIcon } from '../../../Assets/upi.svg';
import { ReactComponent as CashBackIcon } from '../../../Assets/cashback.svg';

export default function OrderSummery(props) {
    const { orderDetails, isNew, orderItems, selectedOrderDetail, orderDetail, kotOrderDetail } = props;
    // const sliceNumber = Math.ceil(orderDetails?.length / 2);
    // const firstColumn = orderDetails?.slice(0, sliceNumber);
    // const secondColumn = orderDetails?.slice(sliceNumber);
    
    return (
        <>
            <div
                className={`flex flex-row justify-between pb-2.5 mb-4 border-neutral-300 md:block ${orderDetails?.length || (selectedOrderDetail?.length && 'border-b')}`}>
               
                    <div className='flex flex-col'>
                        <span>
                            Order Bill Name :{' '}
                            {kotOrderDetail?.customer?.name ||
                                selectedOrderDetail?.customer?.name ||
                                orderDetail?.customer?.name ||
                                ''}
                        </span>
                        <span>
                            Order Date :{' '}
                            {kotOrderDetail?.status?.placed_at ||
                                selectedOrderDetail?.status?.placed_at ||
                                orderDetail?.status?.placed_at ||
                                ''}
                        </span>
                        <span>
                            Order Type :
                            {kotOrderDetail?.ordering_mode ||
                                selectedOrderDetail?.ordering_mode ||
                                orderDetail?.ordering_mode ||
                                ''}
                        </span>
                        {/* <span>Order Taken By : {orderDetails?.ordering_mode_details?.display_name || ''}</span> */}
                        <span>Order Via : -- </span>
                    </div>
                

                <div className='flex flex-col'>
                    
                        {/* <span>Customer's order : {orderDetails?.ordering_mode_details?.display_name || ''}</span> */}
                        <span>
                            Order Time :{' '}
                            {kotOrderDetail?.status?.placed_at ||
                                selectedOrderDetail?.status?.placed_at ||
                                orderDetail?.status?.placed_at ||
                                ''}
                        </span>
                        <span>
                            Payment Method :
                            {kotOrderDetail?.payment_method?.payment_method ||
                                selectedOrderDetail?.payment_method?.payment_method ||
                                orderDetail?.payment_method?.payment_method ||
                                ''}
                        </span>
                        <span>Dishes Ordered : --</span>
                        <span>Platform : -- </span>
                  
                </div>
            </div>
        </>
    );
}

export const Item = ({ item, value, isNew, actionComponent }) => {
    const icons = {
        'Takeaway': <TakeAwayIcon />,
        'Cash': <CashIcon />,
        'Card': <CardIcon />,
        'Credit card': <CardIcon />,
        'Debit card': <CardIcon />,
        'UPI': <UpiIcon />,
        'Delivery': <DeliveryIcon />,
        'Dine-In': <DininIcon />,
        'UPI_INTENT': <UpiIcon />,
        'Balance': <CashBackIcon />,
    };

    const icon = icons[value];

    return (
        <div className='my-1.5 flex flex-row'>
            <span className='paragraph-medium-medium text-neutral-500'>{item}:</span>

            <div className='flex flex-row items-center ml-2'>
                {icon && <span className='mr-1'>{icon}</span>}
                <span className='paragraph-medium-regular'>{value}</span>
                {isNew && (
                    <span className='font-medium text-[10px] leading-3 px-2 py-1 border-primary-500 text-primary-500 bg-primary-50 rounded border ml-1'>
                        New
                    </span>
                )}
                {actionComponent}
            </div>
        </div>
    );
};
