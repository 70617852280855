import React, { useRef, useState } from 'react';
import './styles.css';
import OrderHeader from '../../../../Components/KOT/Components/OrderHeader';
import OrderDishes, { ItemsComponent } from '../../../../Components/KOT/Components/OrderDishes';
import TotalBill from '../../../../Components/KOT/Components/TotalBill';
import OrderActions from '../../../../Components/KOT/Components/OrderActions';
import { useWindowSize } from '@uidotdev/usehooks';
import PidgeAssignedOrder from '../../../../Components/KOT/Components/PidgeAssignedOrder';

export default function ListViewOfKOT(props) {
    const { orderDetail, setShowPopup, onReady, orderItems, setCancelRider, handleClickClose, isLoading } = props;

    const [showWithItems, setShowWithItems] = useState(false);

    const kotRef = useRef();

    const { height: screenHeight } = useWindowSize();

    return (
        <>
            <div
                className='px-4 overflow-auto scrollbar-style -mt-1 pb-3'
                style={{ height: screenHeight - 200 }}
                ref={kotRef}>
                <PidgeAssignedOrder
                    orderDetail={orderDetail}
                    setCancelRider={setCancelRider}
                    setShowPopup={setShowPopup}
                    handleClickClose={handleClickClose}
                />
                <OrderHeader
                    // orderingMode={orderingMode}
                    // icon={icon}
                    // label={label}
                    // listView
                    setShowPopup={setShowPopup}
                    orderDetail={orderDetail}
                    isLoading={isLoading}
                />
                <OrderDishes
                    orderItems={orderItems}
                    isLoading={isLoading}
                />
                <ItemsComponent
                    items={orderDetail?.cart?.cart_items}
                    handleClickWithDropDown={() => setShowWithItems((prevState) => !prevState)}
                    showWithItems={showWithItems}
                    isLoading={isLoading}
                />
                <div className='mb-4 pb-4 border-b border-neutral-300'>
                    <TotalBill
                        orderDetail={orderDetail}
                        isLoading={isLoading}
                    />
                </div>
                <OrderActions
                    orderingMode={orderDetail?.ordering_mode}
                    setShowPopup={setShowPopup}
                    orderDetail={orderDetail}
                    onReady={onReady}
                />
            </div>
        </>
    );
}
