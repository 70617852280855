import React, { useState } from 'react';
import { Items, OtherChargesDetails, Paid, UnPaid } from './Components';
import { useSelector } from 'react-redux';

export default function OrderedDishes(props) {
    const {
        isPaid,
        page,
        otherAmount,
        netTotal,
        extraCharges,
        orderedDishes,
        selectedOrderDetail,
        orderDetail,
        kotOrderDetail,
    } = props;

    const convertToCurrency = netTotal?.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 2,
    });
    const selectedLangaugeId = useSelector((state) => state.langauge.languageId);

    const totalAmount = `${convertToCurrency}/-`;

    const [showDetails, setShoDetails] = useState(
        (page !== 'riderDetails' && page !== 'orderRating' && page !== 'billPayment' && page !== 'loyaltyCashback') ??
            false
    );

    return (
        <>
            <div
                className={`${
                    page === 'failedOrders'
                        ? 'mb-0 border-0'
                        : page === 'orders'
                          ? 'mb-0 border-0'
                          : page === 'orderRating'
                            ? 'mb-4 pb-0 border-b border-neutral-300'
                            : page === 'billPayment'
                              ? 'pb-4 mb-4 border-b border-neutral-300'
                              : 'pb-4 mb-4 border-b border-neutral-300'
                }`}>
                {(page === 'riderDetails' || page === 'orderRating' || page === 'loyaltyCashback') && (
                    <div className='flex flex-row justify-between items-center mb-4'>
                        <h3 className='paragraph-medium-medium'>Order summary</h3>
                        <span
                            className='paragraph-medium-regular text-primary-500 cursor-pointer'
                            onClick={() => setShoDetails((prevState) => !prevState)}>
                            {showDetails ? 'Hide' : 'Show'}
                        </span>
                    </div>
                )}

                {showDetails && (
                    <div className='scrollbar-style md:[&::-webkit-scrollbar]:hidden overflow-auto h-full max-h-[159px] md:max-h-full mb-2 pr-[32px] md:pr-0 -mr-8 md:mr-0 mt-3'>
                        {orderedDishes?.map((el, index) => (
                            <Items
                                {...el}
                                key={index}
                                selectedLangaugeId={selectedLangaugeId}
                            />
                        ))}
                    </div>
                )}

                {page !== 'orderRating' && (
                    <div className={`${page !== 'billPayment' ? 'border-t pt-4 border-neutral-300 w-full' : null}`}>
                        <div>
                            {otherAmount?.map((el, index) => (
                                <div
                                    className='w-full mt-1.5 pb-1.5 last:pb-4 first:mt-0 paragraph-medium-regular'
                                    key={index}>
                                    <OtherChargesDetails
                                        {...el}
                                        extraCharges={extraCharges}
                                    />
                                </div>
                            ))}
                        </div>

                        <div className='paragraph-medium-medium flex flex-row justify-between'>
                            <div className='flex flex-row'>
                                <span>Total bill amount</span>
                                {page !== 'billPayment' && (isPaid ? <Paid /> : <UnPaid />)}
                            </div>

                            <span>
                                ₹
                                {kotOrderDetail?.net_total ||
                                    selectedOrderDetail?.net_total ||
                                    orderDetail?.gross_total}
                                .00
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
