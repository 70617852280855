import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { fetchCategoryList, fetchDishList } from '../../../functions';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';
import DropdownPagination from '../../../../../Components/DropDown/DropdownPagination';
import DropdownCheckboxPagination from '../../../../../Components/DropDown/DropdownCheckboxPagination';

export default function DishCategorySelection() {
    const { control, watch, setValue } = useFormContext();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [categories, setCategories] = useState([]);

    const initialPaginationState = { page: 1, per_page: 10, has_more_pages: null };

    const [categoryPaginationState, setCategoryPaginationState] = useState(initialPaginationState);

    const fetchCategory = async (params) => {
        try {
            const category = await fetchCategoryList(selectedRestaurantId, params);

            const formateCategory = category.data.map((el) => ({ label: el.internal_name, value: el }));

            setCategories((prevState) => [...prevState, ...formateCategory]);

            setCategoryPaginationState((prevState) => ({
                ...prevState,
                page: params.page,
                has_more_pages: category.has_more_pages,
            }));

            return true;
        } catch (error) {
            console.log('error : ', error);
        }
    };

    const [dishPagination, setDishPagination] = useState(initialPaginationState);

    const fetchDish = async (params) => {
        try {
            const dish = await fetchDishList(
                selectedRestaurantId,
                params,
                watch('upSellDish.category')?.value?.category_id
            );

            const selectedDishIds = selectedDish?.map((dish) => dish.value.item_id) ?? [];

            const formateCategory =
                dish?.data?.map((el) => ({
                    label: el.internal_name,
                    value: el,
                    isSelected: selectedDishIds?.includes(el.item_id),
                })) ?? [];

            params.page === 1 ? setDish(formateCategory) : setDish((prevState) => [...prevState, ...formateCategory]);

            setDishPagination((prevState) => ({
                ...prevState,
                page: params.page,
                has_more_pages: dish.has_more_pages,
            }));

            return true;
        } catch (error) {
            console.log('error : ', error);
        }
    };

    useEffect(() => {
        fetchCategory(initialPaginationState);
    }, []);

    useEffect(() => {
        !!watch('upSellDish.category') && fetchDish(initialPaginationState);
    }, [watch('upSellDish.category')]);

    const [dish, setDish] = useState([]);

    const disxh = [
        {
            label: 'Meggie Nick name - title',
            value: {
                item_id: 25734,
                category_id: 2096,
                internal_name: 'Meggie Nick name - title',
                food_type: 'vegetarian',
                is_mapped_item: 0,
                variants_count: 2,
                translations: { title: [], description: [] },
                image_url: null,
            },
            isSelected: false,
        },
        {
            label: 'Indiana Quesadillas-Quesadillas Veg-32--2096-2096',
            value: {
                item_id: 25425,
                category_id: 2096,
                internal_name: 'Indiana Quesadillas-Quesadillas Veg-32--2096-2096',
                food_type: 'vegetarian',
                is_mapped_item: 0,
                variants_count: 0,
                translations: { title: [], description: [] },
                image_url: null,
            },
            isSelected: false,
        },
        {
            label: 'Cheesy Corn Taco-Tacos Veg-32-',
            value: {
                item_id: 18908,
                category_id: 2096,
                internal_name: 'Cheesy Corn Taco-Tacos Veg-32-',
                food_type: 'vegetarian',
                is_mapped_item: 1,
                variants_count: 0,
                translations: { title: [], description: [] },
                image_url: null,
            },
            isSelected: false,
        },
        {
            label: 'variant group id',
            value: {
                item_id: 25749,
                category_id: 2096,
                internal_name: 'variant group id',
                food_type: 'vegetarian',
                is_mapped_item: 0,
                variants_count: 2,
                translations: { title: [], description: [] },
                image_url: null,
            },
            isSelected: false,
        },
        {
            label: 'Veg Cheesy Momos',
            value: {
                item_id: 12541,
                category_id: 2096,
                internal_name: 'Veg Cheesy Momos',
                food_type: 'vegetarian',
                is_mapped_item: 0,
                variants_count: 2,
                translations: { title: [], description: [] },
                image_url: null,
            },
            isSelected: false,
        },
        {
            label: 'testing variant',
            value: {
                item_id: 25750,
                category_id: 2096,
                internal_name: 'testing variant',
                food_type: 'vegetarian',
                is_mapped_item: 0,
                variants_count: 2,
                translations: { title: [], description: [] },
                image_url: null,
            },
            isSelected: false,
        },
        {
            label: 'Choco Lava Cake-Premium Lava Cake-32-',
            value: {
                item_id: 18656,
                category_id: 2096,
                internal_name: 'Choco Lava Cake-Premium Lava Cake-32-',
                food_type: 'vegetarian',
                is_mapped_item: 0,
                variants_count: 1,
                translations: { title: [], description: [] },
                image_url: null,
            },
            isSelected: false,
        },
    ];

    console.log(`const dish = `, JSON.stringify(dish));

    const selectedDish = watch('upSelling.selectedDish') ?? [];

    const handleSelectDish = (isChecked, dish) => {
        setDish((prevState) => {
            return prevState?.map((el) => {
                if (el?.value?.item_id === dish?.value?.item_id) {
                    return {
                        ...el,
                        isSelected: isChecked,
                    };
                }

                return el;
            });
        });

        const updateDish = isChecked
            ? [...selectedDish, { ...dish, category: watch('upSellDish.category') }]
            : selectedDish?.filter((el) => el.value?.item_id !== dish?.value?.item_id);

        setValue('upSelling.selectedDish', updateDish);
    };

    return (
        <>
            <div className='max-w-[661px] pb-8 w-full mt-4'>
                <TitleDescription
                    className='mb-6'
                    title='Up-selling dishes'
                    description='You can add multiple up-selling dishes to your current dish to offer up-gradation or include add-ons to the dish that customers are ordering. '
                />

                <div className='flex flex-row mb-6 gap-3'>
                    <div className='w-1/2 relative'>
                        <Controller
                            render={({ field }) => (
                                <DropdownPagination
                                    label='(Select category)'
                                    labelStyle='paragraph-medium-italic text-neutral-500'
                                    placeholder='Select category'
                                    shadow='shadow-smallDropDownShadow'
                                    menuItems={categories}
                                    fixedHeight='h-[200px]'
                                    fetchMenuItems={fetchCategory}
                                    setPaginationState={setCategoryPaginationState}
                                    paginationState={categoryPaginationState}
                                    selectedItem={field.value}
                                    setSelected={field.onChange}
                                    labelPaddingB='pb-2'
                                    height='h-[52px]'
                                />
                            )}
                            name='upSellDish.category'
                            control={control}
                        />
                    </div>

                    <div className='w-1/2 relative'>
                        <DropdownCheckboxPagination
                            disabled={!watch('upSellDish.category')}
                            label='(Select dish)'
                            labelStyle={`paragraph-medium-italic ${!watch('upSellDish.category') ? 'text-neutral-300' : 'text-neutral-500'}`}
                            placeholder='Select dish'
                            shadow='shadow-smallDropDownShadow'
                            menuItems={dish}
                            fixedHeight='h-[200px]'
                            fetchMenuItems={fetchDish}
                            setPaginationState={setDishPagination}
                            paginationState={dishPagination}
                            labelPaddingB='pb-2'
                            height='h-[52px]'
                            handleChangeCheck={handleSelectDish}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
