import numeral from 'numeral';
import APIV5 from '../../../api/axios/APIV5';
import APIV2 from '../../../api/axios/APIV2';
import { formatDistance } from 'date-fns';
import store from '../../../reduxStore/index';
import APIV3 from '../../../api/axios/APIV3'

const selectedRestaurantId = store.getState().auth.selectedRestaurantId;
const selectedOutletId = store.getState().outlet.selectedOutletId;

export const formatCurrency = (amount) => {
    const formattedAmount = numeral(Math.abs(amount)).format('0,0.00');
    return amount < 0 ? `- ₹${formattedAmount}/-` : `₹${formattedAmount}/-`;
};

export const fetchOrders = async ({
    orderType,
    startDate,
    endDate,
    selectedOrderingMode,
    selectedOutletId,
    selectedRestaurantId,
    selectedFilter,
    perPage,
    page,
}) => {
    const requestedData = {
        start_date: startDate,
        end_date: endDate,
        
        per_page: perPage,
        page: page,
    };

    try {
        let response;

        switch (orderType) {
            case 'live':
                response = await APIV5.get(`/restaurants/${selectedRestaurantId}/orders`, {
                    params: { ...requestedData, status: selectedFilter, ordering_mode: selectedOrderingMode },
                });
                return response.success ? response.orders.data : [];

            case 'past':
                response = await APIV5.get(`/restaurants/${selectedRestaurantId}/past-orders`, {
                    params: {
                        ...requestedData,
                        ...(selectedFilter === 'refunded' && { refund_status: 'Refunded', status: null }),
                        ...(selectedFilter !== 'refunded' &&
                            selectedFilter && { status: selectedFilter, refund_status: null }),
                        keyword: '',
                        ordering_mode: selectedOrderingMode,
                        outlet_ids: [selectedOutletId],
                    },
                });
                return response.success ? response : [];

            case 'failed':
                response = await APIV5.get(`/restaurants/${selectedRestaurantId}/failed-orders`, {
                    params: {
                        ...requestedData,
                        ...(selectedFilter === 'refund_success' && { refund_status: 'refund_success', status: null }),
                        ...(selectedFilter !== 'refund_success' &&
                            selectedFilter && { refund_status: selectedFilter}),
                    },
                });
                return response.success ? response.order_collection : [];
            default:
                return [];
        }
        
    } catch (error) {
        console.error('Error fetching orders:', error);
        return [];
    }
};

export const getTimeAgo = (dateObj) => {
    let date = formatDistance(new Date(dateObj), new Date(), { addSuffix: false });
    return date
        .replace('about', '')
        .replace('ago', '')
        .replace('hours', 'hrs')
        .replace('hour', 'hr')
        .replace('minutes', 'mins')
        .replace('less than a minute', '0 Mins');
};

export const convertToCurrency = (price) => {
    return price.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 2,
    });
};

export const orderStatus = (item) => {
    if (item?.rider_picked_up_at) {
        return 'Dispatched';
    }
    if (item?.restaurant_ready_at) {
        return 'Prepared';
    }
    if (item?.restaurant_accepted_at) {
        return 'Preparing';
    }
    return 'Pending';
};

export const fetchOrderDetail = async (orderId) => {
    try {
        const response = await APIV5.get(`/restaurants/${selectedRestaurantId}/orders/${orderId}`);
        return response ? response?.order : {};
    } catch (error) {
        console.error('Error fetching order details:', error);
        return {};
    }
};

export const changeStatus = async ({ orderId, status, canceledBy, kitchenPreparationTime }) => {
    try {
        const requestedData = {
            status,
            kitchen_preparation_time: kitchenPreparationTime,
            cancelled_by: canceledBy,
        };
        const response = await APIV5.patch(
            `/restaurants/${selectedRestaurantId}/orders/${orderId}/change-status`,
            requestedData
        );
        return response.data?.success || false;
    } catch (error) {
        console.error('Error changing status:', error);
        return false;
    }
};
export const getEstimationTimeForRiderService = async ({ serviceName, orderId }) => {
    try {
        const response =
            serviceName === 'wefast'
                ? await APIV2.post(`/restaurants/${selectedRestaurantId}/${serviceName}/get-order-estimation-time`, {
                      orderId: orderId,
                  })
                : await APIV2.get(`/restaurants/${selectedRestaurantId}/${serviceName}/get-order-estimation-time`, {
                      params: { orderId: orderId },
                  });
        return response || {};
    } catch (error) {
        console.error('Error getting estimation time:', error);
        return { errorMessage: error?.response?.message?.message };
    }
};
export const getQuotation = async ({ orderId }) => {
    try {
        const response = await APIV2.post(`/restaurants/${selectedRestaurantId}/pigde/get-quotation`, {
            order_id: orderId,
        });

        return response?.response;
    } catch (error) {
        return [];
    }
};

export const getRiders = async () => {
    try {
        const response = await APIV2.get(`/restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/riders`);
        return response?.success ? response.riders : [];
    } catch (error) {
        console.error('Error fetching riders:', error);
        return [];
    }
};

export const assignRider = async (
    pidgeData,
    orderId,
    handleClickClose,
    setHasRiderAssigned,
    selectedOrder,
    fullFillStatus,
    setFullFillStatus
) => {
    try {

        const response = await APIV2.post(`/restaurants/${selectedRestaurantId}/pidge/assign-order`, {
            order_id: orderId,
            network_id: pidgeData?.network_id,
            network_name: pidgeData?.network_name,
            service: pidgeData?.service,
            price: pidgeData?.price,
            distance: pidgeData?.distance,
            pickup: pidgeData?.pickup,
            drop: pidgeData?.drop,
            pickup_min: pidgeData?.pickup_min,
            base_delivery_charge: pidgeData?.base_delivery_charge,
            total_gst_amount: pidgeData?.total_gst_amount,
            surge: pidgeData?.surge,
        });
        if (response.success) {
            setHasRiderAssigned(true);

            if ( fullFillStatus) {
                // Make sure to await the fulfill-order request
                await APIV2.post(`/restaurants/${selectedRestaurantId}/pidge/fulfill-order`, {
                    order_id: orderId,
                    selected_service: pidgeData?.network_name,
                });
                setFullFillStatus(false)
            }

            handleClickClose();
            alert('Rider assigned successfully!');
        }

        return response;
    } catch (error) {
        alert(error?.message ?? 'Error in assigning rider');
    }
};
export const acceptOrder = async (orderId) => {
    try{
        const response = await APIV3.get(`restaurants/${selectedRestaurantId}/orders/${orderId}`);
        return response;
    }catch(e){

    }
}
export const riderCancel = async (deliveryService, orderId , handleClickClose) => {
        
        try {
         const response = await APIV2.post(`/restaurants/${selectedRestaurantId}/${deliveryService}/cancel-order`, {
           order_id: orderId ,
        });
       
        if(response?.success){
            alert("Rider cancelled successfully")
            // handleClickClose();
           
        }
        return response;
        } catch (error) {

            alert(error?.message ?? 'Error in assign rider'); 
        }


};
