import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as TimerIcon } from '../../Assets/timer.svg';
import TotalBill from './Components/TotalBill';
import './styles.css';
import OrderHeader from './Components/OrderHeader';
import OrderDishes, { ItemsComponent } from './Components/OrderDishes';
import OrderActions from './Components/OrderActions';
import { fetchOrderDetail } from '../../Screen/Order/Helper/functions';
import PidgeAssignedOrder from './Components/PidgeAssignedOrder';

export default function KOT(props) {
    const {
        icon,
        ordering_mode,
        label,
        tableServed,
        dishPacked,
        dishServed,
        handleClickCancelOrder,
        isUpdated,
        items,
        setShowPopup,
        kotFixedHeight,
        setSelectedOrderDetail,
        setSelectedOrder,
        orders,
        selectedOrderDetail,
        handleClickClose,
        setAssignRider,
        setKotOrderDetail,

    } = props;

    const [showWithItems, setShowWithItems] = useState(false);
    const [showViewDetailsTooltip, setShowViewDetailsTooltip] = useState(false);
    const [selectedKotOrderId, setSelectedKotOrderId] = useState({});
    const [cancelRiders, setCancelRiders] = useState(false);
    const kotRef = useRef();

    const [dishFixedHeight, setDishFixedHeight] = useState(0);
    const [isLoading ,setIsLoading ] = useState(false)

    const handleClickCustomerDetail = () => {
        setShowPopup('customerDetails');
        setSelectedKotOrderId(orders?.order_id);
        setAssignRider(true)
        setKotOrderDetail(orders)
    };

    // const handleClickRiderDetail = () => {
    //     setShowPopup('riderDetails');
    //     setSelectedKotOrderId(orders?.order_id);
    // };
    console.log(isLoading, 'isLoading');
    useEffect(() => {
        setIsLoading(true)
        if (selectedKotOrderId) {
            const fetchData = async () => {
                const response = await fetchOrderDetail(selectedKotOrderId);
                setSelectedOrderDetail(response);
                setSelectedOrder(orders?.details)
                if(response){
                    setIsLoading(false)
                }

            };
            fetchData();
        }
    }, [selectedKotOrderId , cancelRiders]);

    useEffect(() => {
        setDishFixedHeight(kotFixedHeight - kotRef?.current?.offsetHeight);
    }, [kotFixedHeight]);
    // useEffect(() => {
    //     setAssignRider(cancelRiders);
    // } , [cancelRiders])
    return (
        <>
            <div
                className='w-full border border-neutral-300 rounded-xl p-4'
                ref={kotRef}>
                <OrderHeader
                    orderingMode={ordering_mode}
                    icon={icon}
                    label={label}
                    listView
                    setShowPopup={setShowPopup}
                    orderDetail={orders}
                    isLoading={isLoading}
                />
                <PidgeAssignedOrder
                    orderDetail={orders?.details}
                    setCancelRiders={setCancelRiders}
                    setShowPopup={setShowPopup}
                    handleClickClose={handleClickClose}
                />
                <hr />
                <OrderDishes
                    orderDetail={orders?.details?.cart?.cart_items}
                    listView
                    dishPacked={dishPacked}
                    dishServed={dishServed}
                    tableServed={tableServed}
                    isUpdated={isUpdated}
                />
                <ItemsComponent
                    items={orders?.details?.cart?.cart_items}
                    handleClickWithDropDown={() => setShowWithItems((prevState) => !prevState)}
                    showWithItems={showWithItems}
                    dishFixedHeight={kotFixedHeight ? dishFixedHeight : 'auto'}
                    isLoading={isLoading}
                />
                <div className='flex flex-row justify-between paragraph-medium-semi-bold mb-4 pb-4 border-b border-neutral-300'>
                    <TotalBill
                        orderDetail={orders?.details}
                        isLoading={isLoading}
                    />
                </div>

                <div className='flex flex-row justify-between pb-4 mb-4 border-b border-neutral-300'>
                    <span className='paragraph-medium-semi-bold'>
                        {orders?.customer?.name}
                        <span className='paragraph-medium-regular text-primary-500'>
                            {orders?.details?.customer?.orders_count}
                        </span>
                    </span>

                    <div
                        className='relative'
                        onMouseEnter={() => setShowViewDetailsTooltip(true)}
                        onMouseLeave={() => setShowViewDetailsTooltip(false)}>
                        <span
                            // onClick={() => ordering_mode === 'Dine-In order' && setShowPopup('customerDetails')}
                            onClick={handleClickCustomerDetail}
                            className='paragraph-medium-underline text-primary-500 cursor-pointer'>
                            View details
                        </span>

                        {/* {showViewDetailsTooltip && ordering_mode !== 'Dine-In order' && (
                            <div className='border border-neutral-300 rounded-lg p-4 absolute w-[150px] right-0 bg-neutral-50'>
                                <div
                                    className='w-full mb-2 pb-2 cursor-pointer'
                                    onClick={handleClickCustomerDetail}>
                                    Customer details
                                </div>
                                <div
                                    className='cursor-pointer'
                                    onClick={handleClickRiderDetail}>
                                    Rider details
                                </div>
                            </div>
                        )} */}
                    </div>
                </div>

                <OrderActions
                    orderingMode={orders?.details?.ordering_mode}
                    handleClickCancelOrder={handleClickCancelOrder}
                    setShowPopup={setShowPopup}
                    orderDetail={orders?.details}
                />

                <div className='mt-4 pt-4 border-t flex flex-row justify-between paragraph-medium-semi-bold border-neutral-300 text-[#F15B5B]'>
                    <span>Time elapsed</span>

                    <div className='flex flex-row items-center'>
                        <span className='ping-animation absolute h-6 w-6 rounded-full bg-[#F15B5B]' />
                        <div className='w-[20px] h-[20px] rounded-full bg-white absolute animate-none ml-0.5' />
                        <TimerIcon
                            height={24}
                            width={24}
                            stroke='#F15B5B'
                            className='z-50'
                        />

                        <span className='ml-2'>
                            {/* {timeElapsed[0].mins} mins {timeElapsed[1].seconds} secs */}
                            15 mins 16 secs
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}
