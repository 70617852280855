import React from 'react';
import { ReactComponent as CustomersIcon } from '../../../Assets/customers.svg';
import { ReactComponent as EditIcon } from '../../../Assets/edit.svg';
import { ReactComponent as TimerIcon } from '../../../Assets/timer.svg';
import { ReactComponent as TableFile } from '../../../Assets/table.svg';

const Table = (props) => {
    const { tableAllocation, tableName, maxCapacity, price, edit, time, onEditPress, handleClickTable, cursorPointer } =
        props;

    const statusWiseColor = {
        seated: { fill: '#f8f7fd', stroke: '#6C5DD3' },
        reserved: { fill: '#fffaf1', stroke: '#FFCE73' },
        disabled: { fill: '#E4E4E8', stroke: '#D3D2D8' },
        empty: { fill: '#FFFFFF', stroke: '#D3D2D8' },
    };

    const showCapacity = false;

    return (
        <>
            <div
                className={`mx-2.5 mt-4 mb-2 inline-block relative align-top md:mx-1 md:mt-1 md:mb-1 ${cursorPointer ?? 'cursor-default'}`}
                onClick={handleClickTable}>
                <TableFile {...statusWiseColor[tableAllocation]} />

                {tableName && (
                    <div className='absolute inset-0 flex flex-col justify-center items-center'>
                        <div className={`flex ${price ? 'paragraph-medium-regular' : 'paragraph-large-regular'}`}>
                            <div>
                                <div className='flex items-center justify-center'>
                                    <div className='max-w-[150px] break-words text-center mt-1'>
                                        {tableName}
                                        {!showCapacity && (
                                            <div className='flex items-center justify-center ml-1'>
                                                <span> - </span>
                                                <span className='mx-1'>
                                                    <CustomersIcon
                                                        width={16}
                                                        height={16}
                                                    />
                                                </span>
                                                {maxCapacity}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {price && (
                                    <div className='paragraph-medium-regular my-2 lg:my-0.5 lg:text-sm text-center'>
                                        {price}
                                    </div>
                                )}
                                {time && (
                                    <div className='flex flex-row items-center'>
                                        <span className='w-5 lg:w-4'>
                                            <TimerIcon className='w-full h-auto' />
                                        </span>
                                        <span className='paragraph-small-regular ml-1 lg:ml-0.5 lg:text-xs'>
                                            {time}
                                        </span>
                                    </div>
                                )}
                                {showCapacity && (
                                    <div className='flex items-center justify-center paragraph-small-italic text-neutral-500'>
                                        Max. Cap. - {maxCapacity}
                                    </div>
                                )}
                            </div>
                        </div>
                        {edit && (
                            <button
                                className='absolute right-[30px] top-[30px] z-[6]'
                                onClick={onEditPress}>
                                <EditIcon />
                            </button>
                        )}
                    </div>
                )}
                {!tableName && <div className='m-auto text-black paragraph-large-regular'>No tables</div>}
            </div>
        </>
    );
};

export default Table;
