import React from 'react'
import { ReactComponent as Moped } from '../../../Assets/moped.svg';
import { SmallTertiaryButton } from '../../Buttons/Button';
import { riderCancel } from '../../../Screen/Order/Helper/functions';

export default function PidgeAssignedOrder(orderDetail, setCancelRiders, setShowPopup, handleClickClose) {
    console.log(orderDetail, 'orderDetail');
    const pidgeService = orderDetail?.orderDetail?.pidge_order;
    const orderId = pidgeService?.order_id;
    const deliveryService = 'pidge';

    const handleClickCancleRider = async () => {
        // setCancelRiders(true);
        try {
            await riderCancel(deliveryService, orderId, handleClickClose);
            window.location.reload()
        } catch (error) {
            console.error('Failed to assign rider:', error);
        }
    };
    return (
        <div>
            {pidgeService ? (
                <>
                    <div className='px-2 bg-cyan-200'>
                        <p className='flex flex-row text-2xl '>
                            <Moped
                                height={30}
                                width={30}
                            />{' '}
                            <span className='mx-4 font-bold'>Rider Information</span>
                        </p>
                        <p className='font-bold'>
                            Order Id :<span>{pidgeService?.order_id}</span>
                        </p>
                        <p className='font-bold'>
                            Rider service name : <span>{pidgeService?.network_name}</span>
                        </p>
                        <p className='font-bold'>
                            Estimated amount : <span>{`₹${pidgeService?.estimated_price}`}</span>
                        </p>
                        {!pidgeService?.rider_name ? `Waiting for ${pidgeService?.network_name} to assign rider` : ''}
                        <hr />
                        <div
                            onClick={handleClickCancleRider}
                            className='mt-12 md:fixed md:bottom-0 md:w-full md:pr-4 md:pb-2 md:pt-4 md:shadow-dropShadow md:-ml-4 md:pl-4'>
                            <SmallTertiaryButton label='Cancel rider' />
                        </div>
                    </div>
                </>
            ) : (
                ''
            )}
        </div>
    );
}

