import React, { useRef } from 'react';
import { Link } from '../../../Components/LinkOffer/Link';
import IndividualTableRow from '../Components/IndividualTableRow';
import ListViewCustomerGroups from './ListViewCustomerGroups';
import { IsMobileScreen } from '../../../Constants/Constants';
import ListViewCustomerIndividual from './ListViewCustomerIndividual';
import { ReactComponent as DineInIcon } from '../../../Assets/dine-in.svg';
import { ReactComponent as TakeAwayIcon } from '../../../Assets/orders.svg';
import { ReactComponent as DeliveryIcon } from '../../../Assets/riders.svg';
import { ReactComponent as SuccessTickIcon } from '../../../Assets/success-tick.svg';
import { ReactComponent as CancelIcon } from '../../../Assets/cancel.svg';
import { ReactComponent as UPIIcon } from '../../../Assets/upi.svg';
import { ReactComponent as CashIcon } from '../../../Assets/cash.svg';
import { ReactComponent as CardIcon } from '../../../Assets/card.svg';
import ListViewCustomerOrder from './ListViewCustomerOrder';
import ListViewCustomerGroupCustomerDetails from './ListViewCustomerGroupDetails';
import { ListBlankComponentView } from '../../../Components/BlankComponent/ListBlankComponentView';
import { TableBlankComponentView } from '../../../Components/BlankComponent/TableBlankComponentView';
import { CheckBoxWithoutLabels } from '../../../Components/FormControl/FormControls';
import { List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import TableLoader from '../../../Components/ContentLoader/TableLoader';
import ListViewLoader from '../../../Components/ContentLoader/ListViewLoader';

export const TableIndividual = (props) => {
    const {
        isShowBulkSelectButton,
        handleClickCustomerName,
        customerList,
        checkedItems,
        handleCheckboxChange,
        handleClickName,
        isListLoading,
        setCheckedItems,
        fetchCustomersList,
        paginationState,
    } = props;

    const isMobileScreen = IsMobileScreen();

    const selectAll = () => {
        // Check if all riderDetails IDs are in checkedItems
        const allCustomersIds = customerList?.map((el) => el.id);
        const allChecked = allCustomersIds.every((id) => checkedItems.includes(id));

        if (allChecked) {
            setCheckedItems([]);
        } else {
            const missingIds = allCustomersIds.filter((id) => !checkedItems.includes(id));
            setCheckedItems([...checkedItems, ...missingIds]);
        }
    };

    return (
        <>
            {!isMobileScreen ? (
                <div className='w-full border border-neutral-300 rounded-lg overflow-x-auto scrollbar-style mt-4'>
                    {isListLoading ? (
                        <table>
                            <tbody>
                                <TableLoader
                                    hasSwitch={false}
                                    columns={6}
                                />
                            </tbody>
                        </table>
                    ) : (
                        <table className='w-full break-words'>
                            <thead>
                                <tr className='paragraph-overline-small text-neutral-700 bg-neutral-50 text-left'>
                                    <th className='px-6 min-w-[230px] shadow-innerShadow'>
                                        <div className='flex flex-row'>
                                            {isShowBulkSelectButton && (
                                                <div className='mr-4 cursor-pointer'>
                                                    <CheckBoxWithoutLabels onChange={selectAll} />
                                                </div>
                                            )}
                                            CUSTOMER NAME
                                        </div>
                                    </th>
                                    <th className='px-6 py-3 min-w-[207px] shadow-innerShadow'>MOBILE NUMBER</th>
                                    <th className='px-6 py-3 min-w-[245px] shadow-innerShadow'>EMAIL ID</th>
                                    <th className='px-6 py-3 min-w-[163px] shadow-innerShadow'>TOTAL ORDERS</th>
                                    <th className='px-6 py-3 min-w-[197px] shadow-innerShadow'>REVENUE GENERATED</th>
                                    <th className='px-6 py-3 min-w-[194px] shadow-innerShadow'>LAST ORDER</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customerList ? (
                                    customerList.length > 0 && customerList.map((el, index) => (
                                        <IndividualTableRow
                                            customerName={el?.name}
                                            mobileNumber={el?.mobile_number}
                                            email={el?.email}
                                            totalOrder={el?.order_count}
                                            revenueGenerated={el?.life_time_value}
                                            lastOrder={el?.last_order}
                                            key={index}
                                            index={index}
                                            el={el}
                                            isShowBulkSelectButton={isShowBulkSelectButton}
                                            handleClickCustomerName={handleClickCustomerName}
                                            checkedItems={checkedItems}
                                            handleClickName={handleClickName}
                                            handleCheckboxChange={handleCheckboxChange}
                                        />
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={6}>
                                            <TableBlankComponentView previewText={'No Content Available'} />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
            ) : isListLoading ? (
                <div>
                    {[...Array(10)].map((_, index) => (
                        <ListViewLoader
                            key={index}
                            hasSwitch={false}
                        />
                    ))}
                </div>
            ) : (
                <div className='mb-12 mt-3 md:mt-1'>
                    {isShowBulkSelectButton && (
                        <div className='ml-4 cursor-pointer flex flex-row'>
                            {isShowBulkSelectButton && (
                                <div className='cursor-pointer'>
                                    <CheckBoxWithoutLabels onChange={selectAll} />
                                </div>
                            )}
                            <span className='ml-2'>Select all</span>
                        </div>
                    )}
                    {customerList && customerList.length > 0 ? (
                        customerList.map((el, index) => (
                            <ListViewCustomerIndividual
                                key={el?.id}
                                id={el?.id}
                                customerName={el.name}
                                mobileNumber={el.mobile_number}
                                emailId={el.email}
                                totalOrders={el.order_count}
                                revenue={el.life_time_value}
                                lastOrder={el.last_order}
                                handleClickName={handleClickName}
                                isShowBulkSelectButton={isShowBulkSelectButton}
                                handleCheckboxChange={handleCheckboxChange}
                                checkedItems={checkedItems}
                                index={index}
                            />
                        ))
                    ) : (
                        <ListBlankComponentView previewText={'No Content Available'} />
                    )}
                </div>
            )}
        </>
    );
};

export const TableGroup = (props) => {
    const { handleClickGroupName, handleClickLinkOutlet, handleClickCreatedby } = props;

    const isMobileScreen = IsMobileScreen();

    const groupTableDetails = [
        {
            groupName: 'Family',
            customersCount: '02',
            createdBy: 'Sarthak Kanchan',
            createdOn: '20 November, 2022',
            lastUpdated: '20 November, 2022',
            linkOffers: '4',
        },
        {
            groupName: 'Top customers',
            customersCount: '195',
            createdBy: 'Arjun Patel',
            createdOn: '20 November, 2022',
            lastUpdated: '20 November, 2022',
            linkOffers: '4',
        },
        {
            groupName: 'Forgotten customers',
            customersCount: '98',
            createdBy: 'Sarthak Kanchan',
            createdOn: '20 November, 2022',
            lastUpdated: '20 November, 2022',
            linkOffers: '4',
        },
        {
            groupName: 'Regular customers',
            customersCount: '02',
            createdBy: 'Sarthak Kanchan',
            createdOn: '20 November, 2022',
            lastUpdated: '20 November, 2022',
            linkOffers: '4',
        },
    ];
    return (
        <>
            {!isMobileScreen ? (
                <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                    <table className='w-full break-words table-row-bg'>
                        <thead>
                            <tr className='paragraph-overline-small text-neutral-700 shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                <th className='px-6 min-w-[216px] lg:min-w-[190px]'>GROUP NAME</th>
                                <th className='px-6 min-w-[213px] lg:min-w-[187px]'>CUSTOMERS COUNT</th>
                                <th className='px-6 min-w-[204px] lg:min-w-[168px]'>CREATED BY</th>
                                <th className='px-6 min-w-[219px]'>CREATED ON</th>
                                <th className='px-6 min-w-[219px]'>LAST UPDATED</th>
                                <th className='px-6 min-w-[199px]'>LINK OFFER</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groupTableDetails.map((el, index) => {
                                return (
                                    <tr
                                        className='paragraph-small-regular border-t first:border-none border-neutral-300 justify-center h-[70px]'
                                        key={index}>
                                        <td
                                            className='px-6 cursor-pointer'
                                            onClick={handleClickGroupName}>
                                            {el.groupName}
                                        </td>
                                        <td className='px-6'>{el.customersCount}</td>
                                        <td
                                            className='px-6 text-primary-500 cursor-pointer'
                                            onClick={handleClickCreatedby}>
                                            {el.createdBy}
                                        </td>
                                        <td className='px-6'>{el.createdOn}</td>
                                        <td className='px-6'>{el.lastUpdated}</td>
                                        <td
                                            className='px-6 cursor-pointer'
                                            onClick={handleClickLinkOutlet}>
                                            <Link linkOfferNumber={el.linkOffers} />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div>
                    {groupTableDetails.map((el, index) => {
                        return (
                            <div
                                className='mb-2'
                                key={index}>
                                <ListViewCustomerGroups
                                    groupName={el.groupName}
                                    customerCount={el.customersCount}
                                    createdBy={el.createdBy}
                                    createdOn={el.createdOn}
                                    lastUpdated={el.lastUpdated}
                                    linkOffers={el.linkOffers}
                                    viewDetails={handleClickGroupName}
                                    handleClickCreatedby={handleClickCreatedby}
                                    handleClickLinkOutlet={handleClickLinkOutlet}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export const TableOrderDetails = (props) => {
    const {
        handleClickBillName,
        customerOrderDetailList,
        fetchOrderDetailPopup,
        setPopupOrderId,
        setCreateOfferDetails,
        isCustomerOrderListLoading,
    } = props;
    const isMobileScreen = IsMobileScreen();

    const popupOrderingMode = {
        'dine_in': 'Dine-In',
        'delivery': 'Delivery',
        'takeaway': 'Takeaway',
        'CASH_ON_DELIVERY': 'Cash',
        'CREDIT_CARD/DEBIT_CARD': 'Card',
        'CREDIT_CARD': 'Credit card',
        'DEBIT_CARD': 'Debit card',
        'UPI': 'UPI',
        'UPI_INTENT': 'UPI',
        'BALANCE': 'Balance',
    };

    return (
        <>
            {!isMobileScreen ? (
                <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                    {isCustomerOrderListLoading ? (
                        <table>
                            <tbody>
                                <TableLoader
                                    hasSwitch={false}
                                    columns={7}
                                />
                            </tbody>
                        </table>
                    ) : (
                        <table className='w-full break-words table-row-bg'>
                            <thead>
                                <tr className='paragraph-overline-small text-neutral-700 shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                    <th className='px-6 min-w-[231px]'>ORDER ID</th>
                                    <th className='px-6 min-w-[174px]'>ORDER DATE</th>
                                    <th className='px-6 min-w-[155px]'>ORDER TYPE</th>
                                    <th className='px-6 min-w-[167px]'>ORDER STATUS</th>
                                    <th className='px-6 min-w-[163px]'>ITEMS ORDERED</th>
                                    <th className='px-6 min-w-[197px]'>REVENUE GENERATED</th>
                                    <th className='px-6 min-w-[183px]'>PAYMENT MODE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customerOrderDetailList && customerOrderDetailList.length > 0 ? (
                                    customerOrderDetailList.map((el, index) => (
                                        <tr
                                            className='paragraph-small-regular border-t first:border-none border-neutral-300 justify-center h-[70px]'
                                            key={index}>
                                            <td
                                                className='px-6 cursor-pointer text-primary-500 hover:underline underline-offset-3'
                                                onClick={() => {
                                                    handleClickBillName();
                                                    fetchOrderDetailPopup(el?.id);
                                                    setPopupOrderId(el?.id);
                                                    setCreateOfferDetails((prevState) => ({
                                                        ...prevState,
                                                        id: el?.id,
                                                    }));
                                                }}>
                                                {el?.id}
                                            </td>
                                            <td className='px-6'>{el?.order_date}</td>
                                            <td className='px-6'>
                                                <div className='flex flex-row items-center'>
                                                    {el?.orderType === 'Dine-In' ? (
                                                        <DineInIcon />
                                                    ) : el?.orderType === 'Takeaway' ? (
                                                        <TakeAwayIcon />
                                                    ) : (
                                                        <DeliveryIcon />
                                                    )}
                                                    <span className='ml-1'>{popupOrderingMode[el?.order_type]}</span>
                                                </div>
                                            </td>

                                            <td className='px-6'>
                                                <div className='flex flex-row items-center'>
                                                    {el?.order_status.customer_picked_up_at !== null ||
                                                    el?.order_status.rider_delivered_at !== null ||
                                                    el?.order_status.delivered_at !== null ? (
                                                        <>
                                                            <SuccessTickIcon />
                                                            <span className='ml-1'>Delivered</span>
                                                        </>
                                                    ) : null}
                                                    {el?.order_status === 'restaurant_cancelled' ||
                                                    el?.order_status === 'customer_cancelled' ||
                                                    el?.order_status === 'cancelled' ? (
                                                        <>
                                                            <CancelIcon />
                                                            <span className='ml-1'>Cancelled</span>
                                                        </>
                                                    ) : null}
                                                </div>
                                            </td>
                                            <td className='px-6'>{el?.items_count_ordered}</td>
                                            <td className='px-6'>{el?.revenue_generated}</td>
                                            <td className='px-6'>
                                                <div className='flex flex-row items-center'>
                                                    {el?.payment_method === 'UPI' ||
                                                    el?.payment_method === 'UPI_INTENT' ? (
                                                        <UPIIcon />
                                                    ) : el?.payment_method === 'CASH_ON_DELIVERY' ? (
                                                        <CashIcon />
                                                    ) : el?.payment_method === 'CREDIT_CARD/DEBIT_CARD' ? (
                                                        <CardIcon />
                                                    ) : el?.payment_method === 'CREDIT_CARD' ? (
                                                        <CardIcon />
                                                    ) : el?.payment_method === 'DEBIT_CARD' ? (
                                                        <CardIcon />
                                                    ) : (
                                                        <CardIcon />
                                                    )}
                                                    <span className='ml-1'>
                                                        {popupOrderingMode[el?.payment_method]}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={7}>
                                            <TableBlankComponentView previewText={'No Content Available'} />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
            ) : isCustomerOrderListLoading ? (
                <div>
                    {[...Array(10)].map((_, index) => (
                        <ListViewLoader
                            key={index}
                            hasSwitch={false}
                        />
                    ))}
                </div>
            ) : (
                <div className='mb-12 mt-4'>
                    {customerOrderDetailList && customerOrderDetailList.length > 0 ? (
                        customerOrderDetailList.map((el, index) => (
                            <ListViewCustomerOrder
                                orderId={el?.id}
                                orderDate={el?.order_date}
                                orderType={popupOrderingMode[el?.order_type]}
                                orderStatus={el?.order_status}
                                itemsOrdered={el?.items_count_ordered}
                                revenueGenerated={el?.revenue_generated}
                                paymentMode={popupOrderingMode[el?.payment_method]}
                                viewDetails={handleClickBillName}
                                fetchOrderDetailPopup={fetchOrderDetailPopup}
                                setPopupOrderId={setPopupOrderId}
                                setCreateOfferDetails={setCreateOfferDetails}
                                index={index}
                            />
                        ))
                    ) : (
                        <ListBlankComponentView previewText={'No Content Available'} />
                    )}
                </div>
            )}
        </>
    );
};

export const TableGroupDetails = (props) => {
    const isMobileScreen = IsMobileScreen();
    const tableDetails = [
        {
            customerName: 'Riddhi Shah',
            mobileNumber: '(+91) 8866886688',
            emailId: 'riddhi.shah@gmail.com',
            totalOrder: '713',
            revenueGenerated: '₹559.00/-',
            lastOrder: '22 Sep 2022',
        },
        {
            customerName: 'Amrendra Bahubali',
            mobileNumber: '(+91) 8866886688',
            emailId: 'riddhi.shah@gmail.com',
            totalOrder: '713',
            revenueGenerated: '₹559.00/-',
            lastOrder: '22 Sep 2022',
        },
    ];

    return (
        <>
            {!isMobileScreen ? (
                <div className='mt-6'>
                    <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                        <table className='w-full break-words table-row-bg'>
                            <thead>
                                <tr className='paragraph-overline-small text-neutral-700 shadow-innerShadow bg-neutral-50 text-left h-11 justify-center'>
                                    <th className='px-6 min-w-[271px] lg:min-w-[187px]'>CUSTOMER NAME</th>
                                    <th className='px-6 min-w-[196px] lg:min-w-[178px]'>MOBILE NUMBER</th>
                                    <th className='px-6 min-w-[230px] lg:min-w-[202px]'>EMAIL ID</th>
                                    <th className='px-6 min-w-[162px] lg:min-w-[163px]'>TOTAL ORDER</th>
                                    <th className='px-6 min-w-[215px]'>REVENUE GENERATED</th>
                                    <th className='px-6 min-w-[190px]'>LAST ORDER</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableDetails.map((el, index) => {
                                    return (
                                        <tr
                                            className='paragraph-small-regular border-t first:border-none border-neutral-300 justify-center h-[70px]'
                                            key={index}>
                                            <td className='px-6'>{el.customerName}</td>
                                            <td className='px-6'>{el.mobileNumber}</td>
                                            <td className='px-6'>{el.emailId}</td>
                                            <td className='px-6'>{el.totalOrder}</td>
                                            <td className='px-6'>{el.revenueGenerated}</td>
                                            <td className='px-6'>{el.lastOrder}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div>
                    {tableDetails.map((el, index) => {
                        return (
                            <div
                                className='mb-2'
                                key={index}>
                                <ListViewCustomerGroupCustomerDetails
                                    customerName={el.customerName}
                                    mobileNumb={el.mobileNumber}
                                    emailId={el.emailId}
                                    totalOrder={el.totalOrder}
                                    revenue={el.revenueGenerated}
                                    lastOrder={el.lastOrder}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};
