import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as LiveOrderIcon } from '../../../Assets/live-order.svg';
import { ReactComponent as PastOrderIcon } from '../../../Assets/past-order.svg';
import { ReactComponent as FailedOrderIcon } from '../../../Assets/failed-order.svg';
import { ReactComponent as BillPaymentIcon } from '../../../Assets/bill-payments.svg';
import { Tab } from '../../../Components/Tabs/Tabs';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { DropDownTabs } from '../../../Components/DropDown/DropDownTabs';
import { IsIpadScreen, IsMobileScreen } from '../../../Constants/Constants';
import MobileOrderingMode from '../../../Components/PosComponents/MobileOrderingMode';
import { useWindowSize } from '@uidotdev/usehooks';
import useOutsideClick from '../../../Helper/Hooks/useOutsideClick';
import { useSelector } from 'react-redux';

const Header = (props) => {
    const { setShowPopup, setIsKotView, setSelectedOrderingMode, setSelectedFilter, resetState, orderType } = props;
    const orderingModes = useSelector((state) => state.outlet?.orderingModes);
    const orderStatusTabs = [
        { label: 'Live order', icon: LiveOrderIcon, path: '/orders/live' },
        { label: 'Past order', icon: PastOrderIcon, path: '/orders/past' },
        { label: 'Failed order', icon: FailedOrderIcon, path: '/orders/failed' },
        { label: 'Bill payment', icon: BillPaymentIcon, path: '/orders/bill-payment' },
    ];

    const { pathname } = useLocation();

    const navigate = useNavigate();

    const isMobileScreen = IsMobileScreen();

    const handleOrderStatusClick = (path) => {
        if (path.includes(orderType)) {
            return;
        }
        resetState();
        navigate(path);
    };

    const orderingModeMenuItems = [
        { item: 'All', value: 'all', badgeText: '6', onClick: () => setSelectedOrderingMode('all') },
        ...orderingModes.map((item) => {
            return {
                item: item.display_name,
                value: item.tab,
                badgeText: '4',
                onClick: () => setSelectedOrderingMode(item.tab),
            };
        }),
    ];
    // all,placed,restaurant_accepted,restaurant_ready,rider_picked_up,delivered
    const orderStatusLive = [
        { item: 'All', value: 'all', onClick: () => setSelectedFilter('all') },
        { item: 'Pending', value: 'placed', onClick: () => setSelectedFilter('placed') },
        { item: 'Preparing', value: 'restaurant_accepted', onClick: () => setSelectedFilter('restaurant_accepted') },
        { item: 'Prepared', value: 'restaurant_ready', onClick: () => setSelectedFilter('restaurant_ready') },
        { item: 'Dispatched', value: 'rider_picked_up', onClick: () => setSelectedFilter('rider_picked_up') },
    ];
    const orderStatusPast = [
        { item: 'All', value: 'all', onClick: () => setSelectedFilter('all') },
        { item: 'Delivered', value: 'delivered', onClick: () => setSelectedFilter('delivered') },
        { item: 'Cancelled', value: 'cancelled', onClick: () => setSelectedFilter('cancelled') },
        { item: 'Refunded', value: 'refunded', onClick: () => setSelectedFilter('refunded') },
    ];
    const orderStatusFailed = [
        { item: 'All', value: 'all', onClick: () => setSelectedFilter('all') },
        { item: 'Refund successfully', value: 'refund successfully', onClick: () => setSelectedFilter('refunded') },
        { item: 'Refund failed', value: 'refund failed', onClick: () => setSelectedFilter('failed') },
        { item: 'Refund initiated', value: 'refund initiated', onClick: () => setSelectedFilter('initiated') },
    ];

    const { width: screenWidth } = useWindowSize();

    const viewControllerDropdown = [
        { item: 'List view', onClick: () => setIsKotView(false) },
        { item: 'KOT view', onClick: () => setIsKotView(true) },
    ];

    const [showViewDropdown, setShowViewDropdown] = useState(false);

    const viewOptionRef = useRef(null);

    useOutsideClick(viewOptionRef, () => setShowViewDropdown((prevState) => !prevState));

    return (
        <>
            <div className='flex flex-row justify-between items-center pb-4 mb-4 border-b border-neutral-300 md:flex-col md:gap-4'>
                {screenWidth > 1200 ? (
                    <div className='flex flex-row items-center'>
                        {orderStatusTabs.map((tab, index) => (
                            <div
                                className='mr-4 xl:mr-2'
                                onClick={() => handleOrderStatusClick(tab.path)}
                                key={index}>
                                <Tab
                                    label={tab.label}
                                    iconDefault={<tab.icon />}
                                    iconClick={<tab.icon stroke='#6C5DD3' />}
                                    isActive={pathname === tab.path}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className='w-full max-w-[213px] md:max-w-full'>
                        <DropDownTabs
                            menuItems={orderStatusTabs.map((tab, index) => ({
                                item: tab.label,
                                icon: <tab.icon />,
                                iconClick: <tab.icon stroke='#6C5DD3' />,
                                onClick: () => handleOrderStatusClick(tab.path),
                            }))}
                        />
                    </div>
                )}

                {pathname === '/orders/live' && (
                    <div className='flex flex-row items-center gap-4 lg:gap-2 md:w-full'>
                        <div className='max-w-[331px] lg:max-w-[200px] w-full md:max-w-full'>
                            <DropDownTabs
                                menuItems={orderingModeMenuItems}
                                fixedLabel={screenWidth > 1023 ? 'Ordering mode ' : 'O.M.'}
                                menuButtonStyle='border-neutral-300'
                                badgeTextStyle='bg-neutral-200'
                                dropDownIconFill='#131126'
                                boxStyle='border-neutral-300'
                                textColor='text-neutral-900'
                            />
                        </div>

                        <div className='min-w-[180px] lg:min-w-[180px] w-full md:max-w-full'>
                            <DropDownTabs
                                fixedLabel='Filter'
                                menuItems={orderStatusLive}
                                boxStyle='border-neutral-300'
                                textColor='text-neutral-900'
                                menuButtonStyle='border-neutral-300'
                                dropDownIconFill='#131126'
                            />
                        </div>

                        {!isMobileScreen && (
                            <div className='relative'>
                                <div
                                    className='flex flex-col gap-1 cursor-pointer p-2 -m-2'
                                    onClick={() => setShowViewDropdown((prevState) => !prevState)}>
                                    <div className='bg-black h-1 w-1 rounded-full'></div>
                                    <div className='bg-black h-1 w-1 rounded-full'></div>
                                    <div className='bg-black h-1 w-1 rounded-full'></div>
                                </div>

                                {showViewDropdown && (
                                    <div
                                        className='border border-neutral-300 rounded-xl px-4 w-[112px] absolute top-6 bg-white right-0'
                                        ref={viewOptionRef}>
                                        {viewControllerDropdown?.map((el, index) => {
                                            return (
                                                <div
                                                    className='py-1.5 cursor-pointer paragraph-medium-regular'
                                                    onClick={() => {
                                                        el.onClick();
                                                        setShowViewDropdown((prevState) => !prevState);
                                                    }}
                                                    key={index}>
                                                    <span>{el.item}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {pathname === '/orders/past' && (
                    <div className='flex flex-row items-center gap-4 lg:gap-2 md:w-full'>
                        <div className='max-w-[331px] lg:max-w-[200px] w-full md:max-w-full'>
                            <DropDownTabs
                                menuItems={orderingModeMenuItems}
                                fixedLabel={screenWidth > 1023 ? 'Ordering mode ' : 'O.M.'}
                                menuButtonStyle='border-neutral-300'
                                badgeTextStyle='bg-neutral-200'
                                dropDownIconFill='#131126'
                                boxStyle='border-neutral-300'
                                textColor='text-neutral-900'
                            />
                        </div>

                        <div className='min-w-[180px] lg:min-w-[180px] w-full md:max-w-full'>
                            <DropDownTabs
                                fixedLabel='Filter'
                                menuItems={orderStatusPast}
                                boxStyle='border-neutral-300'
                                textColor='text-neutral-900'
                                menuButtonStyle='border-neutral-300'
                                dropDownIconFill='#131126'
                            />
                        </div>

                        {!isMobileScreen && (
                            <div className='relative'>
                                <div
                                    className='flex flex-col gap-1 cursor-pointer p-2 -m-2'
                                    onClick={() => setShowViewDropdown((prevState) => !prevState)}>
                                    <div className='bg-black h-1 w-1 rounded-full'></div>
                                    <div className='bg-black h-1 w-1 rounded-full'></div>
                                    <div className='bg-black h-1 w-1 rounded-full'></div>
                                </div>

                                {showViewDropdown && (
                                    <div
                                        className='border border-neutral-300 rounded-xl px-4 w-[112px] absolute top-6 bg-white right-0'
                                        ref={viewOptionRef}>
                                        {viewControllerDropdown?.map((el, index) => {
                                            return (
                                                <div
                                                    className='py-1.5 cursor-pointer paragraph-medium-regular'
                                                    onClick={() => {
                                                        el.onClick();
                                                        setShowViewDropdown((prevState) => !prevState);
                                                    }}
                                                    key={index}>
                                                    <span>{el.item}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {pathname === '/orders/failed' && (
                    <div className='flex flex-row items-center gap-4 lg:gap-2 md:w-full'>
                        <div className='max-w-[331px] lg:max-w-[200px] w-full md:max-w-full'>
                            <DropDownTabs
                                menuItems={orderingModeMenuItems}
                                fixedLabel={screenWidth > 1023 ? 'Ordering mode ' : 'O.M.'}
                                menuButtonStyle='border-neutral-300'
                                badgeTextStyle='bg-neutral-200'
                                dropDownIconFill='#131126'
                                boxStyle='border-neutral-300'
                                textColor='text-neutral-900'
                            />
                        </div>

                        <div className='min-w-[180px] lg:min-w-[180px] w-full md:max-w-full'>
                            <DropDownTabs
                                fixedLabel='Filter'
                                menuItems={orderStatusFailed}
                                boxStyle='border-neutral-300'
                                textColor='text-neutral-900'
                                menuButtonStyle='border-neutral-300'
                                dropDownIconFill='#131126'
                            />
                        </div>

                        {!isMobileScreen && (
                            <div className='relative'>
                                <div
                                    className='flex flex-col gap-1 cursor-pointer p-2 -m-2'
                                    onClick={() => setShowViewDropdown((prevState) => !prevState)}>
                                    <div className='bg-black h-1 w-1 rounded-full'></div>
                                    <div className='bg-black h-1 w-1 rounded-full'></div>
                                    <div className='bg-black h-1 w-1 rounded-full'></div>
                                </div>

                                {showViewDropdown && (
                                    <div
                                        className='border border-neutral-300 rounded-xl px-4 w-[112px] absolute top-6 bg-white right-0'
                                        ref={viewOptionRef}>
                                        {viewControllerDropdown?.map((el, index) => {
                                            return (
                                                <div
                                                    className='py-1.5 cursor-pointer paragraph-medium-regular'
                                                    onClick={() => {
                                                        el.onClick();
                                                        setShowViewDropdown((prevState) => !prevState);
                                                    }}
                                                    key={index}>
                                                    <span>{el.item}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>

            {isMobileScreen && <MobileOrderingMode orderingModes={orderingModes} />}
        </>
    );
};

export default Header;
