import React, { useEffect, useState } from 'react';
import { ReactComponent as SearchIcon } from '../../../Assets/search.svg';
import { ReactComponent as SelectIcon } from '../../../Assets/select.svg';
import { ReactComponent as EditIcon } from '../../../Assets/edit.svg';
import { ReactComponent as ReArrangeIcon } from '../../../Assets/re-arrange.svg';
import { ReactComponent as MenuIcon } from '../../../Assets/menu.svg';
import { ReactComponent as AddIcon } from '../../../Assets/add.svg';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { DefaultInputField } from '../../../Components/InputField/InputField';
import CreatePresetPopup from '../Popups/CreatePresetPopup';
import SelectCategoriesDishesPopup from '../Popups/SelectCategoriesDishesPopup';
import { IsMobileScreen } from '../../../Constants/Constants';
import UserDetailsPopup from '../../../Components/UserDetailsPopup/UserDetailsPopup';

export default function HeaderButtonsAndPopups(props) {
    const [headerButton, setHeaderButton] = useState([]);
    const [showCreatePreset, setShowCretePreset] = useState(false);
    const [searchPlaceholder, setSearchPlaceholder] = useState('Search category or dish');
    const [showSelectCategoryPopup, setShowSelectCategoryPopup] = useState(false);
    
    const {
        setEditMenu,
        page,
        toggleShowRenderPage,
        setIsRearrange,
        editMenu,
        isRearrange,
        showUserDetails,
        handleClickCreatedBy,
    } = props;

    const showHideSelectCategoryPopup = () => {
        setShowSelectCategoryPopup(!showSelectCategoryPopup);
        page === 'Catalogue' || page === 'Catalogue presets'
            ? setShowCretePreset(!showCreatePreset)
            : toggleShowRenderPage();
    };

    const handleClickCretePreset = () => {
        toggleShowRenderPage();
        setShowCretePreset(!showCreatePreset);
    };

    const handleClickEditButton = () => {
        setEditMenu((prevState) => !prevState);
        setIsRearrange((prevState) => (prevState === true ? false : prevState));
    };

    const handleClickReArrangeButton = () => {
        setIsRearrange((prevState) => !prevState);
        setEditMenu((prevState) => (prevState === true ? false : prevState));
    };

    const editButton = {
        label: 'Edit',
        isClicked: editMenu,
        leftIconDefault: <EditIcon stroke='#FFFFFF' />,
        leftIconClick: <EditIcon stroke='#C4BEED' />,
        onClick: handleClickEditButton,
    };

    const reArrangeButton = {
        label: 'Re-arrange',
        isClicked: isRearrange,
        leftIconDefault: <ReArrangeIcon stroke='#FFFFFF' />,
        leftIconClick: <ReArrangeIcon stroke='#C4BEED' />,
        onClick: handleClickReArrangeButton,
    };

    const addOnGroupsButton = {
        label: 'Add-on groups',
        onClick: () => {},
    };

    const createPresetButton = {
        label: 'Create preset',
        isDefault: false,
        leftIconDefault: <AddIcon stroke='#FFFFFF' />,
        leftIconClick: <AddIcon stroke='#C4BEED' />,
        onClick: handleClickCretePreset,
    };

    const manageMenuButton = {
        label: 'Manage menu',
        isDefault: false,
        leftIconDefault: <MenuIcon stroke='#FFFFFF' />,
        leftIconClick: <MenuIcon stroke='#C4BEED' />,
        onClick: showHideSelectCategoryPopup,
    };

    const pageToHeaderButtonMap = {
        'Catalogue': [editButton, reArrangeButton, addOnGroupsButton, createPresetButton],
        'Catalogue presets': [createPresetButton],
        'Outlet menu details': [editButton, reArrangeButton, addOnGroupsButton, manageMenuButton],
    };

    

    useEffect(() => {
        const buttonsForPage = pageToHeaderButtonMap[page] || [];
        setHeaderButton(buttonsForPage);
        switch (page) {
            case 'Catalogue':
                setSearchPlaceholder('Search category or dish');
                break;

            case 'Catalogue presets':
                setSearchPlaceholder('Search preset');
                break;

            case 'Outlet menu':
                setSearchPlaceholder('Search oultet');
                break;

            default:
                break;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, editMenu, isRearrange]);


    const handleClickProceed = () => {
        showHideSelectCategoryPopup();
        toggleShowRenderPage(false);
        setShowCretePreset(!showCreatePreset);
    };


    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {!((showCreatePreset || showSelectCategoryPopup) && isMobileScreen) && (
                <div
                    className={`flex flex-row justify-between mb-6 md:mb-4 ${
                        page !== 'Catalogue presets' ? 'xl:block' : 'md:block'
                    }`}>
                    <div className={`flex flex-row md:mb-1 ${page === 'Catalogue' && 'items-center xl:mb-4'}`}>
                        <div className='min-w-[375px] mr-4 w-full pageContentSmall:min-w-[330px] xl:max-w-[298px] pageContentSmall:mr-2 md:max-w-full xl:min-w-0'>
                            <DefaultInputField
                                placeholder={searchPlaceholder}
                                placeholderIcon={<SearchIcon stroke='#D3D2D8' />}
                                shadow='shadow-xSmall'
                            />
                        </div>

                        <div className='max-w-[156px] md:max-w-none md:w-[64px]'>
                            <LargePrimaryButton
                                label='Bulk select'
                                hideLabel='md:hidden'
                                leftIconDefault={<SelectIcon stroke='#ffffff' />}
                                leftIconClick={<SelectIcon stroke='#C4BEED' />}
                            />
                        </div>
                    </div>

                    <div
                        className={`flex flex-row xl:justify-end md:block xl:pt-4 md:pt-0 ${
                            page !== 'Catalogue presets' && 'items-center'
                        }`}>
                        {headerButton.map((el, index) => (
                            <div
                                className={`ml-4 pageContentSmall:ml-2 md:ml-0 md:inline-block md:w-1/2 even:md:pl-2 md:mt-2 md:align-top cursor-pointer ${
                                    page === 'Catalogue presets' ? 'md:w-full' : 'odd:md:pr-2'
                                }`}
                                onClick={el.onClick}
                                key={index}>
                                <LargePrimaryButton
                                    label={el.label}
                                    leftIconClick={el.leftIconClick}
                                    isDefault={el.isDefault}
                                    leftIconDefault={el.leftIconDefault}
                                    hideLabel='mobile:text-sm'
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {showCreatePreset && (
                <CreatePresetPopup
                    handleClickProceed={handleClickProceed}
                    handleClickClose={handleClickCretePreset}
                />
            )}

            {showSelectCategoryPopup && (
                <SelectCategoriesDishesPopup
                    handleClickClose={showHideSelectCategoryPopup}
                    page={page}
                />
            )}

            {showUserDetails && <UserDetailsPopup handleClickClose={handleClickCreatedBy} />}
        </>
    );
}
