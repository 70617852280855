import React from 'react';
import { ReactComponent as VegIcon } from '../../../Assets/vegetable-icon.svg';
import AddButton from './AddButton';

export default function DishCard(props) {
    // const { image, dishName, variants, price, stock } = props;
    const { setShowPopup, item } = props;
    const itemInternalName = item.internal_name;
    const itemImageUrl = item.image_url;
    const orderingModesArray = JSON.parse(item?.ordering_modes);
    const price = orderingModesArray?.[0].price
   const itemStocks = orderingModesArray?.map((mode) => mode?.stock);

    const cardStyle = `h-[160px] pageContent:h-[144px] md:h-auto p-4 md:px-3 md:py-[11px] flex flex-row items-start ${
        itemImageUrl
            ? 'w-[411px] pageContent:w-[401px] lg:w-full'
            : 'w-[195px] pageContent:w-[190px] lg:w-[160px] md:w-full'
    }`;

    return (
        <>
            <div className='border cursor-pointer border-neutral-300 rounded-md'>
                <div
                    className={cardStyle}
                    onClick={() => setShowPopup('customizeVariant')}>
                    {itemImageUrl && (
                        <img
                            alt=''
                            src={
                                itemImageUrl ||
                                'https://buffer.com/library/content/images/size/w1200/2023/10/free-images.jpg'
                            }
                            style={{
                                filter: itemStocks === 0 ? 'grayscale(100%)' : 'none',
                                opacity: itemStocks === 0 ? 0.5 : 1,
                            }}
                            className='mr-4 w-[128px] h-[128px] pageContent:w-[110px] pageContent:h-[110px] md:w-[74px] md:h-[74px] md:mr-2 rounded'
                        />
                    )}

                    <div className='flex flex-col justify-between w-full h-full'>
                        <div className='w-full'>
                            <div className='flex flex-row items-start justify-between mb-2'>
                                <span className='paragraph-medium-medium md:paragraph-small-medium text-black pageContent:text-sm'>
                                    {itemInternalName}
                                </span>
                                <VegIcon />
                            </div>

                            {/* <div className='hidden md:block paragraph-x-small-italic text-neutral-500'>{variants}</div> */}
                            <div>
                                <span className='paragraph-small-regular pageContent:text-xs'>${price}\-</span>
                            </div>
                        </div>

                        <div className='flex justify-end w-full'>
                            <div className='h-[30px]'>
                                <AddButton disabled={itemStocks === 0} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
