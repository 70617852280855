import React from 'react';
import { MultipleTab } from '../../../../Components/Tabs/Tabs';
import { ReactComponent as EditIcon } from '../../../../Assets/edit.svg';
import { useNavigate, useParams } from 'react-router-dom';
import DropdownWithValueLabel from '../../../../Components/DropDown/DropdownWithValueLabel';
import { IsMobileScreen } from '../../../../Constants/Constants';
import ContentLoader from 'react-content-loader';
import { fetchTable } from '../../HelperFunction/ApiFunctions';
import { useSelector } from 'react-redux';

export default function SectionTab(props) {
    const {
        isEditMode,
        sectionList,
        setPopupStatus,
        setValue,
        setloaderStatus,
        setTableList,
        setTotalTable,
        setShowTableContent,
    } = props;
    const { section } = useParams();
    const navigate = useNavigate();
    const isLaptopScreen = IsMobileScreen();
    const sections = sectionList.map((item) => ({ label: item.internal_name, value: item.id }));
    const selectedItem = sections?.find((el) => el.label == section);
    const isMobileScreen = IsMobileScreen();
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const handleNavigation = (value) => {
        //this function is used to navigate to section and fetch table of selected section
        if (value == section) return;
        fetchTable({
            setloaderStatus,
            setTableList,
            setTotalTable,
            section: value,
            sectionList,
            selectedOutletId,
        });
        navigate(`/table-management/${value}`);
    };

    const openSectionModel = ({ modal, index }) => {
        //this function is used to open edit section modal and set selected section data for editing
        const selectedItemForEdit = sectionList?.find((item) => item?.id == index);
        const editedData = {
            sectionId: selectedItemForEdit?.id,
            internal_name: selectedItemForEdit?.internal_name,
            subSection: selectedItemForEdit?.sub_section?.map((item) => ({ name: item?.internal_name, id: item?.id })),
            ordering_mode: {
                label: selectedItemForEdit?.ordering_mode?.ordering_mode_type,
                value: selectedItemForEdit?.ordering_mode?.id,
            },
        };
        isMobileScreen && setShowTableContent(false);
        Object.entries(editedData).forEach(([name, value]) => setValue(name, value));
        setPopupStatus((prevStatus) => ({
            ...Object.fromEntries(Object.keys(prevStatus).map((kay) => [kay, false])),
            [modal]: true,
        }));
    };
    return (
        <>
            {sections.length > 0 ? (
                !isLaptopScreen ? (
                    <div className='flex flex-row scrollbar-style overflow-x-auto w-full pb-2'>
                        {sections.map((el, index) => {
                            const isActive = section == el.label;
                            return (
                                <div
                                    key={index}
                                    className='mr-2 3xl:mr-4 pageContent:mr-2 flex-none'>
                                    <MultipleTab
                                        icon={
                                            isEditMode && (
                                                <EditIcon
                                                    className='z-[7]'
                                                    stroke={`${isActive ? '#6C5DD3' : '#131126'}`}
                                                />
                                            )
                                        }
                                        label={el.label}
                                        isActive={isActive}
                                        index={index}
                                        onClick={() => handleNavigation(el.label)}
                                        maxWidth='max-w-none'
                                        showIcon={true}
                                        textWrap={'whitespace-nowrap'}
                                        inIconClick={() => openSectionModel({ modal: 'addSection', index: el.value })}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className='w-full max-w-[185px] md:w-3/4 md:mr-2 md:max-w-full'>
                        <DropdownWithValueLabel
                            menuItems={sections}
                            icon={
                                isEditMode && (
                                    // <button
                                    //     onClick={() =>
                                    //         openSectionModel({ modal: 'addSection', index: selectedItem.value })
                                    //     }>
                                    <EditIcon />
                                    // </button>
                                )
                            }
                            shadow='shadow-xSmall'
                            setSelected={(selection) => handleNavigation(selection.label)}
                            selectedItem={selectedItem}
                            iconClick={() => openSectionModel({ modal: 'addSection', index: selectedItem.value })}
                        />
                    </div>
                )
            ) : (
                <div className='w-full my-2 h-full'>
                    <ContentLoader
                        width='100%'
                        height='100%'
                        className='h-[50px] w-full'
                        backgroundColor='#d7d8d8'>
                        <rect
                            x='0'
                            y='0'
                            rx='4'
                            ry='4'
                            width='100%'
                            height='45'
                        />
                    </ContentLoader>
                </div>
            )}
        </>
    );
}
