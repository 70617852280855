import React, { useEffect, useRef, useState } from 'react';

export const Switch = () => {
    return (
        <>
            <div className='switch-toggle'>
                <label
                    htmlFor='toggleSwitch'
                    className='flex items-center cursor-pointer'>
                    <div className='relative'>
                        <input
                            type='checkbox'
                            id='toggleSwitch'
                            className='sr-only'
                            defaultChecked
                        />
                        <div className='block bg-neutral-300 switch-button w-10 h-6 rounded-[20px]'></div>
                        <div className='dot absolute left-0.5 top-0.5 bg-white w-5 h-5 rounded-full transition'></div>
                    </div>
                </label>
            </div>
        </>
    );
};

export const RadioButton = (props) => {
    const { handleOptionChange, marginL, marginT, marginR, marginB, optionId, labelStyle, paddingL, label, disabled } =
        props;

    const [checked, setChecked] = useState(props.checked);

    const handleChangeOption = () => {
        handleOptionChange && handleOptionChange(optionId);
    };

    return (
        <>
            <div
                className={`radio-button flex flex-row items-center relative ml-${marginL} mt-${marginT} mr-${marginR} mb-${marginB} `}>
                <input
                    type='radio'
                    id={optionId}
                    name='radioGroup'
                    onChange={handleChangeOption}
                    defaultChecked={checked}
                    disabled={disabled}
                />
                <label
                    htmlFor={optionId}  
                    className='radio-label'>
                    <span
                        className={`${labelStyle ?? 'paragraph-small-regular'} pl-${paddingL ?? '5'} ${disabled ? 'text-red-600' : ''}`}>
                        {label}
                    </span>
                </label>
            </div>
        </>
    );
};
export const CheckBox = (props) => {
    const { isChecked, setIsChecked, itemPosition, onClick, item, disabled } = props;

    const handleCheckboxChange = () => {
        onClick?.();
        setIsChecked?.(!isChecked);
        props?.onChange?.(!isChecked , item);    
    };

    return (
        <>
            <div className={`checkbox-style flex flex-row relative ${itemPosition ?? 'items-center'} ${props.margin}`}>
                <input
                    type='checkbox'
                    className='mr-2'
                    id={props.optionId}
                    disabled={disabled}
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />

                {props.label && (
                    <label
                        htmlFor={props.optionId}
                        className='checkbox-label'>
                        <div
                            className={`${props.labelStyle ?? 'paragraph-small-medium'} ml-2 pl-${props.paddingL ?? '2'} ${disabled ? 'text-destructive-500' : ''}`}>
                            {props.label}
                        </div>
                    </label>
                )}
            </div>
        </>
    );
};

export const CheckBoxWithoutLabels = (props) => {
    const { onChange, disabled, isIndeterminate } = props;

    const [isChecked, setIsChecked] = useState(props.isChecked ?? false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        onChange?.(!isChecked);
    };

    const checkboxRef = useRef(null);

    useEffect(() => {
        props.isChecked !== undefined && setIsChecked(props.isChecked);
    }, [props.isChecked]);

    useEffect(() => {
        if (checkboxRef.current) {
            checkboxRef.current.indeterminate = isIndeterminate;
        }
    }, [isIndeterminate]);

    return (
        <>
            <div className='checkbox-without-label'>
                <input
                    ref={checkboxRef}
                    type='checkbox'
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    disabled={disabled}
                />
                <div className='checkbox-without-label-visible'></div>
            </div>
        </>
    );
};
