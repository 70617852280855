import React, { useState } from 'react';
import OrderDetailCard from '../../Components/OrderDetailCard';
import { ReactComponent as UserIcon } from '../../../../Assets/user.svg';
import { ReactComponent as SuccessIcon } from '../../../../Assets/success-tick.svg';
import { ReactComponent as ReimbursementIcon } from '../../../../Assets/reimbursement.svg';
import { ReactComponent as TakeAwayIcon } from '../../../../Assets/orders.svg';
import { ReactComponent as DeliveryIcon } from '../../../../Assets/riders.svg';
import { ReactComponent as DininIcon } from '../../../../Assets/dine-in.svg';
import { ReactComponent as LocationIcon } from '../../../../Assets/location.svg';
import { useWindowSize } from '@uidotdev/usehooks';

export const TakeAwayCard = (props) => {
    const { setShowPopup } = props;

    return (
        <>
            <div className='flex flex-row mb-2 pb-4 border-b border-neutral-300'>
                <div
                    className='mr-1 w-full cursor-pointer'
                    onClick={() => setShowPopup('customerInformation')}>
                    <OrderDetailCard
                        icon={
                            <UserIcon
                                className='mx-auto'
                                stroke='#3D8C82'
                            />
                        }
                        statusIcon={
                            <SuccessIcon
                                width={16}
                                height={16}
                            />
                        }
                        title='Mr. Sarthak Kanchan'
                        textColor='text-tertiary-800'
                        truncate={false}
                    />
                </div>

                <div className='mx-1 w-full'>
                    <OrderDetailCard
                        icon={
                            <TakeAwayIcon
                                className='mx-auto'
                                stroke='#3D8C82'
                            />
                        }
                        statusIcon={
                            <SuccessIcon
                                width={16}
                                height={16}
                            />
                        }
                        title='Takeaway'
                        textColor='text-tertiary-800'
                        truncate={true}
                    />
                </div>
            </div>
        </>
    );
};

export const DinInCard = (props) => {
    const [ isDropdownOpen, setIsDropdownOpen ] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState('Dine-In');
    const { setShowPopup } = props;

        const orderingModeMenuItems = [
            { item: 'All', badgeText: '6' },
            { item: 'Dine-in', badgeText: '1' },
            { item: 'Takeaway', badgeText: '2' },
            { item: 'Delivery', badgeText: '4' },
        ];

        const handleCardClick = () => {
            setIsDropdownOpen((prevState) => !prevState);
        };
    
        const handleMenuItemClick = (item) => {
            setSelectedTitle(item);
            setIsDropdownOpen(false);
        };
    
    const screenWidth = useWindowSize().width;

    return (
        <>
            <div className='flex flex-row mb-2 pb-4 border-b border-neutral-300'>
                <div
                    className='mr-1 w-full cursor-pointer'
                    onClick={() => setShowPopup('enterCustomerDetails')}>
                    <OrderDetailCard
                        icon={
                            <UserIcon
                                className='mx-auto'
                                stroke='#CB8400'
                            />
                        }
                        statusIcon={
                            <ReimbursementIcon
                                width={16}
                                height={16}
                            />
                        }
                        title='Customer info.'
                        textColor='text-secondary-800'
                        truncate={screenWidth < 1400 ? true : false}
                    />
                </div>

                <div className='mx-1 w-full cursor-pointer relative'>
                    <OrderDetailCard
                        icon={
                            <DininIcon
                                className='mx-auto'
                                stroke='#3D8C82'
                            />
                        }
                        statusIcon={
                            <SuccessIcon
                                width={16}
                                height={16}
                            />
                        }
                        title={selectedTitle}
                        textColor='text-tertiary-800'
                        truncate={screenWidth < 1400 ? true : false}
                        onClick={() => handleCardClick()}
                    />
                    {isDropdownOpen && (
                        <div className='absolute mt-2 w-full z-[999] bg-white shadow-lg'>
                            {orderingModeMenuItems.map((menuItem, index) => (
                                <div
                                    key={index}
                                    className='flex justify-between items-center p-2 border-b border-neutral-300 cursor-pointer'
                                    onClick={() => handleMenuItemClick(menuItem.item)}>
                                    <span>{menuItem.item}</span>
                                    <span className='bg-neutral-200 px-2 py-1 rounded'>{menuItem.badgeText}</span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className='ml-1 w-full'>
                    <OrderDetailCard
                        statusIcon={
                            <ReimbursementIcon
                                stroke='#CB8400'
                                width={16}
                                height={16}
                            />
                        }
                        title='Table number'
                        textColor='text-secondary-800'
                        truncate={screenWidth < 1400 ? true : false}
                    />
                </div>
            </div>
        </>
    );
};

export const DeliveryCard = (props) => {
    const { setShowPopup } = props;

    return (
        <>
            <div className='flex flex-row mb-2 pb-4 border-b border-neutral-300'>
                <div
                    className='mr-1 w-full cursor-pointer'
                    onClick={() => setShowPopup('customerInformation')}>
                    <OrderDetailCard
                        truncate={true}
                        icon={
                            <UserIcon
                                className='mx-auto'
                                stroke='#3D8C82'
                            />
                        }
                        statusIcon={
                            <SuccessIcon
                                width={16}
                                height={16}
                            />
                        }
                        title='Mr. Sarthak Kanchan'
                        textColor='text-tertiary-800'
                    />
                </div>

                <div className='mx-1 w-full'>
                    <OrderDetailCard
                        icon={
                            <DeliveryIcon
                                className='mx-auto'
                                stroke='#3D8C82'
                            />
                        }
                        statusIcon={
                            <SuccessIcon
                                width={16}
                                height={16}
                            />
                        }
                        title='Delivery'
                        textColor='text-tertiary-800'
                    />
                </div>

                <div className='ml-1 w-full'>
                    <OrderDetailCard
                        statusIcon={
                            <SuccessIcon
                                width={16}
                                height={16}
                            />
                        }
                        icon={
                            <LocationIcon
                                className='mx-auto'
                                stroke='#3D8C82'
                            />
                        }
                        title='Location'
                        textColor='text-tertiary-800'
                    />
                </div>
            </div>
        </>
    );
};
